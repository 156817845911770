// eslint-disable-next-line import/no-anonymous-default-export
export default {
  recent: "Récent",
  trending: "Tendance",
  active: "Actif",
  fellows: "Camarades",
  prime: "Prime",
  business: "Entreprise",
  lifestyle: "Mode de vie",
  book: "Livre",
  testPrep: "Test Préparation",
  viewAll: "Voir tout",

  english: "Anglais",
  french: "Français",
  en: "EN",
  fr: "FR",

  //Categories
  fellowCat: "Classes de catégorie Fellow",
  otherCat: "Classes de la catégorie Autres",
  businessCat: "Classes de catégorie affaires",
  bookCat: "Classes de catégorie du livre",
  testPrepCat: "Classes de catégorie de préparation aux tests",
  lifestyleCat: "Classes de catégorie de style de vie",

  //No data
  noData: "Pas de données",
  noCatAvailable: "No Categories available",
  noClasses: "Pas de classes pour cette catégorie",
  backToCat: "Retour aux catégories",
  noItem: "Aucun élément trouvé",
  noCourses: "Aucun cours disponible",
  noTest: "Aucun cours disponible",
  classNotFound: "La classe est introuvable",
  DiscussionNotFound: "Discussions introuvable",

  backHome: "De retour à la maison",
  noContentProviderFound: "Le fournisseur de contenu est introuvable",
  backToContent: "Retour aux fournisseurs de contenu",
  noClassInterest: "Pas de cours pour cet intérêt",
  backToInterest: "Retour aux centres d'intérêt",
  noInterest: "Aucun centre d'intérêt disponible",
  noPoints: "Aucun point disponible",
  getMorePoints: "Obtenez plus de points en prenant plus de cours",
  noPaymentAvailable: "Aucun paiement disponible",
  selectedAllInterest: "Sélectionné tous les intérêts",
  noClassAvailable: "Aucune classe disponible",
  noComments: "No comments foun",
  noTopic: "Aucun sujet disponible",
  noCourseFound: "Le cours est introuvable",
  noAnnouncement: "Aucune annonce disponible",

  //Content ---- Providers
  noContentProviders: "Aucun fournisseur de contenu disponible",
  contentProvider: "Fournisseur de contenu",
  details: "Détails",
  country: "Pays",
  classes: "Des classes",

  //Subscriptions
  allSubscription: "Tous les abonnements",
  subscribe: "S'abonner",
  unSubscribe: "Se désabonner",
  subscribed: "Abonné",
  paid: "Payé",
  expired: "Expiré",
  subscribeToView: "Abonnez-vous pour voir le contenu du cours",
  noSubscriptions: "Aucun abonnement",

  //category --- class
  category: "Catégorie",
  description: "La description",
  totalCourses: "Total des cours",
  courses: "Cours",
  test: "Essais",

  //Profile
  myProfile: "Mon profil",
  profileDetails: "Afficher les détails du profil personnel.",
  editProfile: "Editer le profil",
  firstName: "Prénom",
  lastName: "Nom de famille",
  city: "Ville",
  institute: "Institut",
  changePass: "Changer le mot de passe",
  oldPass: "Ancien mot de passe",
  newPass: "Nouveau mot de passe",
  confirmPass: "Confirmez le mot de passe",
  deleteAccount: "Supprimer le compte",
  about: "Sur moi",
  payment: "Paiements",
  interest: "Intérêts",
  profile: "Profil ",
  username: "Nom d'utilisateur",
  ecampusID: "ID eCampus",
  referralID: "ID de parrainage",
  totalPoints: "Points totaux",
  updateProfile: "Mettre à jour votre profil",
  updateInterest: "Mettre à jour les centres d'intérêt",
  allInterest: "Tous les intérêts",

  //Public pages
  freeClasses: "Cours gratuits",
  publicClasses: "Cours publics",
  paidClasses: "Cours payants",
  jhsClasses: "Cours JHS",
  shsClasses: "Cours SHS",
  classesTopic: "Sujet de classe",

  //Buttons
  cancel: "Annuler",
  update: "Mise à jour",
  continue: "Continuer...",
  save: "Sauvegarder",
  submit: "Soumettre",
  post: "Poste",
  resetPassword: "Réinitialiser le mot de passe",
  resetPasswordP:
    "Entrez votre adresse e-mail et nous vous enverrons un e-mail avec des instructions pour réinitialiser votre mot de passe.",

  //Sub components
  startLearning: "Commencer à apprendre",
  duration: "Durée",
  startTest: "Démarrer l'essai",
  takeTestNow: "Faites un test maintenant",
  takeTest: "Passer un test",
  contentAdminTest: "Contactez l'administrateur pour vous inscrire à ce test",
  subToStartLearn: "Abonnez-vous pour commencer à apprendre",
  subToStartTest: "Abonnez-vous pour commencer le test",
  topics: "Sujets",
  totalTopics: "Nombre total de sujets",
  recentTopics: "Sujets récents",
  courseOutline: "Plan de cours",
  announcement: "Annonces",
  announcementDetails: "Détails de l'annonce",
  //Discussions
  comments: "commentaires",
  addDiscuss: "Ajouter une discussion",

  //ToolTips
  read: "Lis",
  listen: "Écoutez",
  practice: "Pratique",
  watch: "Regardez",
  discuss: "Discuter",

  //Audios
  loading: "Chargement en cours",
  paused: "En pause",
  playing: "En jouant",

  //auth Pages
  signIn: "S'identifier",
  signUp: "S'inscrire",
  signOut: "Déconnexion",
  email: "E-mail",
  password: "Mot de passe",
  forgotPassword: "mot de passe oublié?",
  noAccount: "Je n'ai pas de compte",
  or: "ou",
  signInGoogle: "Connectez-vous avec Google",
  signUpGoogle: "Inscrivez-vous avec Google",
  confirmPassword: "Confirmez le mot de passe",
  passwordError: "Le mot de passe doit comporter 8 caractères",
  gotAnAccount: "Vous avez déjà un compte",
  emailAddress: "Adresse e-mail",

  //Main Page
  eStudents: "eCampus pour étudiants",
  eStudentsP:
    "Apprendre est devenu plus facile. Fixez-vous des objectifs, passez des tests de préparation à tout moment et améliorez-vous. Aucune restriction. Passez au niveau supérieur !",
  eTeacher: "Devenir enseignant",
  eBusiness: "eCampus pour les entreprises",
  eBusinessP:
    "Coachez et formez vos employés sans tracas supplémentaires. Comprendre leurs points forts dans votre entreprise.",
  eTeacherP:
    "Transmettre des connaissances tout en gagnant un revenu supplémentaire. Aidez à affiner les connaissances des étudiants de toute l'Afrique.",

  //WhiteLabel
  events: "Événements",
  noEvents: "Aucun événement disponible",
  loadMore: "Chargez plus",
  cpNotFound: "Le fournisseur de contenu n'est pas trouvé",
  searchHere: "Que voulez-vous apprendre?",
  tellUsAboutYourself: "Parlez nous de vous",
  home: "Maison",
  contentProviders: "Fournisseurs de contenu",
  subscriptions: "Mes classes",
  categories: "Catégories",
  interests: "Intérêts",
  tests: "Tests",
  announcements: "Annonces",
  forum: "Forum",
  success: "Succès",
  aMailHasBeenSend:
    "Un message a été envoyé à votre adresse électronique. Veuillez vérifier si vous avez reçu un e-mail de la société et cliquez sur le lien inclus pour réinitialiser votre mot de passe.",
  interestClasses: "Classes d'intérêt",
  questions: "Questions",
  payToAccessContent: "Payer pour accéder au contenu",
  payNow: "Payez maintenant",
  interestBased: "Sur la base de vos intérêts",
  sorryYouCannot: "Désolé, vous ne pouvez pas accéder à ce contenu ici",
  clickHereToAccessContent: "Cliquez ici pour accéder au contenu",
  signInTele: "S'inscrire avec Telecel",
  telecelID: "Telecel ID",
  sendCode: "Envoyer le code",
  code: "Code",
  phoneNumber: "Numéro de téléphone",
  accountNumber: "Numéro de compte",
  changePhoneNumber: "Changement de numéro de téléphone",
  changeAccountNumber: "Changement de numéro de compte",
  signInUsernameEmail: "S'identifier avec le nom d'utilisateur/l'e-mail",
  signInUsernameEmailOthers: "S'identifier avec le nom d'utilisateur ou l'e-mail ou Telecel ID",
  dateTimeLanguage: "fr-FR",
  skip: "Sauter",
};
