import React from "react";
import Card from "./Card";
import { ClassResponse } from "../services/api.response.service";
import { RouteEndpoints } from "../services/route.service";
import { Link } from "react-router-dom";
import { CheckContentProviderIdExist } from "../services/app.service";
import { useSelector } from "react-redux";
import { IRootState } from "../redux/states";

interface Props {
  data: ClassResponse;
  showUnAuthModal?: () => void;
}

function ClassCard1({ data, showUnAuthModal }: Props) {
  const { ecampus_id } = useSelector(
    (state: IRootState) => state.setting.user!
  );
  return (
    <Card className="card-block card-stretch card-height product content-card-container">
      <Card.Body className="p-1 d-flex flex-column">
        <Link
          to={ RouteEndpoints.CLASS_DETAILS(String(data.link_name)) }
          className="d-flex flex-column text-decoration-none"
          onClick={showUnAuthModal!}
        >
          <div className="image-block position-relative">
            <img
              src={data.logo_url}
              className="img-fluid h-100 rounded"
              alt="product-img"
            />

            {/*     
            {!CheckContentProviderIdExist() && (
              <h6 className="price">
                {data.subscription_amount_month > 0
                  ? data.subscription_currency.concat(
                      " ",
                      String(data.subscription_amount_month)
                    )
                  : "Free"}
              </h6>
            )} */}
          </div>
          <div className="p-1 product-description mt-3 line-height-16">
            <h3 className="mb-1 fs-5 fw-normal">
              {data.name.length > 27
                ? data.name.slice(0, 27).concat("..")
                : data.name}
            </h3>

            {/* <small className="mb-0 text-body fs-7">
              {data.description.length > 38
                ? data.description.slice(0, 38).concat("...")
                : data.description}
            </small> */}

            <small className="mb-0 text-body fs-7">
              <svg width="15" height="15" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg" className="m-1">
                <g clip-path="url(#clip0_2013_6136)">
                  <path d="M6.53092 7.16679V6.50013C6.53092 6.14651 6.3933 5.80737 6.14833 5.55732C5.90337 5.30727 5.57113 5.16679 5.22469 5.16679H2.61225C2.26582 5.16679 1.93358 5.30727 1.68861 5.55732C1.44365 5.80737 1.30603 6.14651 1.30603 6.50013V7.16679" stroke="#9E9E9E" stroke-width="0.635188" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M3.91914 3.83335C4.64054 3.83335 5.22536 3.2364 5.22536 2.50002C5.22536 1.76364 4.64054 1.16669 3.91914 1.16669C3.19773 1.16669 2.61292 1.76364 2.61292 2.50002C2.61292 3.2364 3.19773 3.83335 3.91914 3.83335Z" stroke="#9E9E9E" stroke-width="0.635188" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_2013_6136">
                    <rect width="7.83733" height="8" fill="white" transform="translate(0.00012207 0.166779)"/>
                  </clipPath>
                </defs>
              </svg>

              {data.content_provider.name}
            </small>
          </div>
        </Link>
        {/*{!CheckContentProviderIdExist() && (*/}
        {/*  <div className="d-flex justify-content-between flex-column">*/}
        {/*    <hr className="hr-horizontal" />*/}
        {/*    <div className="d-flex justify-content-between align-items-center">*/}
              {/* <div>
                <small>
                  <i className="text-warning fa fa-star" />
                  <small className="ms-1">4.3</small>
                </small>
              </div>
              <small className="d-flex align-items-center gap-1">
                <i className="fa fa-language"></i>{" "}
                <span style={{ fontSize: 10 }}>{data.language}</span>
              </small> */}
              {/*<small>*/}
              {/*  {data.category && (*/}
              {/*    <Link*/}
              {/*      to={*/}
              {/*            RouteEndpoints.CATEGORY_DETAILS(*/}
              {/*              String(data.category.link_name)*/}
              {/*            )*/}
              {/*      }*/}
              {/*      className="text-decoration-none"*/}
              {/*      onClick={showUnAuthModal}*/}
              {/*    >*/}
              {/*      {data.category.name}*/}
              {/*    </Link>*/}
              {/*  )}*/}
              {/*</small>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
      </Card.Body>
    </Card>
  );
}

export default ClassCard1;
