import React, { useEffect, useState } from "react";
import { Button, Col, Nav, Row, Spinner, Tab } from "react-bootstrap";
import Card from "../../components/Card";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import { BaseState, StateKeys } from "../../redux/states/main.state";
import {
  AppPageScrollDownLoadMore,
  AppState,
  CheckContentProviderIdExist,
  getContentProviderId,
  getLanguageByDefault,
  InfoLogger,
  SearchLocal,
} from "../../services/app.service";
import {
  ClassResponse,
  CourseResponse,
  SubscriptionResponse,
  TestResponse,
  TestResultResponse,
} from "../../services/api.response.service";
import Main from "../../redux/actions/main.action";
import ApiEndpoint from "../../services/api.endpoint.service";
import { RouteComponentProps, useParams } from "react-router";
import NoData from "./NoData";
import { RouteEndpoints } from "../../services/route.service";
import Loader from "../../components/Loader";

import CourseCardItems from "../../components/CourseCardItems";
import TestsCardList from "../../components/TestsCardList";
import GoBack from "../../components/GoBack";

import ViewCourse from "../../components/classCourse/ViewCourse";
// import Languages from "../../components/Languages";

function ClassCourses({ location }: RouteComponentProps) {

    const { ecampus_id } = useSelector(
        (state: IRootState) => state.setting.user!
    );

  let { id } = useParams<{ id: string }>();
  let { link } = useParams<{ link: string }>();
  // const id = location.pathname.replace(RouteEndpoints.CLASS_COURSES(""), "");
  const tab = location.hash.replace("#", "");
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);
  const getLanguage = getLanguageByDefault();

  const dataPersist = state[StateKeys.DATA_PERSIST];
  const details: BaseState<Array<CourseResponse>, ClassResponse> = state[
    StateKeys.CLASS_COURSES
  ];
  const tests: BaseState<Array<TestResponse>> = state[StateKeys.CLASS_TESTS];
  const topics = state[StateKeys.CLASS_COURSE_TOPICS];
  const testResults: BaseState<Array<TestResultResponse>> =
    state[StateKeys.CLASS_TEST_RESULTS];
  const subscriptions: BaseState<Array<SubscriptionResponse>> =
    state[StateKeys.USER_SUBSCRIPTION];
  const subscription =
    subscriptions.data.length > 0
      // ? subscriptions.data.find((e) => String(e.subscriber_class.id) === getClassId())
      ? subscriptions.data.find((e) => String(e.subscriber_class.id) === "0")
      : undefined;

  const [search, setSearch] = useState("");

  const [showCourseDetails, setShowCourseDetails] = useState<CourseResponse>();
  const [showTopicDetails, setShowTopicDetails] = useState<CourseResponse>();
  const activeClass: BaseState<ClassResponse> = state[StateKeys.ACTIVE_CLASS];

  const [showTests, setShowTests] = useState<TestResponse[]>([]);
  const [showCourses, setShowCourses] = useState<CourseResponse[]>([]);
  const [hasSubscribed, setHasSubscribed] = useState<boolean>(true);

  let getClassId = () => {
      let theId = (String((activeClass.data as ClassResponse).id))
      // let theId = ""
      if (theId && theId !== "" && theId !== undefined && theId !== 'undefined' && theId !== null) {
          id = theId
          // return theId
      }

      // if(id && id !== ""){
      if (id && id !== "" && id !== undefined && id !== 'undefined' && id !== null) {
            return id
      }
      // if (link && link !== "") {
      //       return link
      // }
      // return (String((activeClass.data as ClassResponse).id));
      return '';
  }

  useEffect(() => {
    if (
      details.pageState !== AppState.LOADED ||
      (details.pageState === AppState.LOADED &&
        String((details.obj as ClassResponse).id) !== getClassId())
    ) {
      // console.log(dataPersist.data[StateKeys.CLASS_COURSES.concat('-',id)],(details.pageState === AppState.LOADED && String((details.obj as ClassResponse).id) !== id))
      if (
        dataPersist.data &&
        dataPersist.data[StateKeys.CLASS_COURSES.concat("-", getClassId())]
      ) {
        dispatch(
          Main.postPageObjectDataSuccess({
            stateKey: StateKeys.CLASS_COURSES,
            data: dataPersist.data[StateKeys.CLASS_COURSES.concat("-", getClassId())].obj,
            clear: true,
          })
        );
        dispatch(
          Main.postPaginatedPageDataSuccess({
            stateKey: StateKeys.CLASS_COURSES,
            data: dataPersist.data[StateKeys.CLASS_COURSES.concat("-", getClassId())]
              .data,
            clear: true,
          })
        );
        dispatch(
          Main.togglePageState({
            stateKey: StateKeys.CLASS_COURSES,
            data: dataPersist.data[StateKeys.CLASS_COURSES.concat("-", getClassId())]
              .pageState,
          })
        );
      } else {
        dispatch(
          Main.postPaginatedPageObjectSuccess({
            stateKey: StateKeys.CLASS_COURSES,
            data: {},
            clear: true,
          })
        );
        dispatch(
          Main.postPaginatedPageDataSuccess({
            stateKey: StateKeys.CLASS_COURSES,
            data: [],
            clear: true,
          })
        );
        dispatch(
          Main.getPaginatedPageData({
            stateKey: StateKeys.CLASS_COURSES,
            apiEndpoint: ApiEndpoint.CLASS_COURSES((getClassId())),
            previousPage: 0,
            obj: true,
            storeKey: StateKeys.CLASS_COURSES.concat("-", getClassId()),
          })
        );
      }
      if (
        dataPersist.data &&
        dataPersist.data[StateKeys.CLASS_TESTS.concat("-", getClassId())]
      ) {
        InfoLogger(
          "test",
          getClassId(),
          dataPersist.data[StateKeys.CLASS_TESTS.concat("-", getClassId())]
        );
        dispatch(
          Main.postPaginatedPageDataSuccess({
            stateKey: StateKeys.CLASS_TESTS,
            data: dataPersist.data[StateKeys.CLASS_TESTS.concat("-", getClassId())].data,
            clear: true,
          })
        );
        dispatch(
          Main.togglePageState({
            stateKey: StateKeys.CLASS_TESTS,
            data: dataPersist.data[StateKeys.CLASS_TESTS.concat("-", getClassId())]
              .pageState,
          })
        );
      } else {
        dispatch(
          Main.postPaginatedPageDataSuccess({
            stateKey: StateKeys.CLASS_TESTS,
            data: [],
            clear: true,
          })
        );
        dispatch(
          Main.getPaginatedPageData({
            stateKey: StateKeys.CLASS_TESTS,
            apiEndpoint: ApiEndpoint.CLASS_TESTS((getClassId())),
            previousPage: 0,
            storeKey: StateKeys.CLASS_TESTS.concat("-", getClassId()),
          })
        );
      }
    } else if (tests.pageState !== AppState.LOADED) {
      if (
        dataPersist.data &&
        dataPersist.data[StateKeys.CLASS_TESTS.concat("-", getClassId())]
      ) {
        dispatch(
          Main.postPaginatedPageDataSuccess({
            stateKey: StateKeys.CLASS_TESTS,
            data: dataPersist.data[StateKeys.CLASS_TESTS.concat("-", getClassId())].data,
            clear: true,
          })
        );
        dispatch(
          Main.togglePageState({
            stateKey: StateKeys.CLASS_TESTS,
            data: dataPersist.data[StateKeys.CLASS_TESTS.concat("-", getClassId())]
              .pageState,
          })
        );
      } else {
        dispatch(
          Main.postPaginatedPageDataSuccess({
            stateKey: StateKeys.CLASS_TESTS,
            data: [],
            clear: true,
          })
        );
        dispatch(
          Main.getPaginatedPageData({
            stateKey: StateKeys.CLASS_TESTS,
            apiEndpoint: ApiEndpoint.CLASS_TESTS((getClassId())),
            previousPage: 0,
            storeKey: StateKeys.CLASS_TESTS.concat("-", getClassId()),
          })
        );
      }
    }

    // if (
    //   activeClass.pageState !== AppState.LOADED ||
    //   (activeClass.pageState === AppState.LOADED &&
    //     String((activeClass.data as ClassResponse).id) !== getClassId())
    // ) {
    //         console.log("get id",getClassId())
    //         console.log("class id",String((activeClass.data as ClassResponse).id))
    //         console.log("main id",id)
    //         console.log("link",link)

            dispatch(
                Main.getPageObjectData({
                    stateKey: StateKeys.ACTIVE_CLASS,
                    // apiEndpoint: ApiEndpoint.ACTIVE_CLASS((getClassId())),
                    apiEndpoint: ApiEndpoint.ACTIVE_CLASS((link ?? id)),
                })
            );
        // }
    // }
  }, [link,getClassId()]);

  useEffect(() => {
    if (
      details.hasMore &&
      details.pageState !== AppState.LOADING_MORE &&
      details.pageState === AppState.LOADED
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.CLASS_COURSES,
          apiEndpoint: ApiEndpoint.CLASS_COURSES((getClassId())),
          previousPage: details.currentPage!,
          storeKey: StateKeys.CLASS_COURSES.concat("-", getClassId()),
        })
      );
    }

    setShowCourses(details.data);
    if (details.obj !== null) {
      setHasSubscribed(details.obj!.is_subscribed);
    }
  }, [details]);

  useEffect(() => {
    if (
      tests.hasMore &&
      tests.pageState !== AppState.LOADING_MORE &&
      tests.pageState === AppState.LOADED
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.CLASS_TESTS,
          apiEndpoint: ApiEndpoint.CLASS_TESTS((getClassId())),
          previousPage: tests.currentPage!,
          storeKey: StateKeys.CLASS_TESTS.concat("-", getClassId()),
        })
      );
    }

    setShowTests(tests?.data);
  }, [tests]);

  const loadMore = () => {
    // @ts-ignore
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      // @ts-ignore
      document.scrollingElement.scrollHeight
    ) {
      // @ts-ignore
      if (details.hasMore && details.pageState !== AppState.LOADING_MORE) {
        dispatch(
          Main.getPaginatedPageData({
            stateKey: StateKeys.CLASS_COURSES,
            apiEndpoint: ApiEndpoint.CLASS_COURSES((getClassId())),
            previousPage: details.currentPage!,
            storeKey: StateKeys.CLASS_COURSES.concat("-", getClassId()),
          })
        );
      }
      // @ts-ignore
      if (tests.hasMore && tests.pageState !== AppState.LOADING_MORE) {
        dispatch(
          Main.getPaginatedPageData({
            stateKey: StateKeys.CLASS_TESTS,
            apiEndpoint: ApiEndpoint.CLASS_TESTS((getClassId())),
            previousPage: tests.currentPage!,
            storeKey: StateKeys.CLASS_TESTS.concat("-", getClassId()),
          })
        );
      }
    }
  };

  useEffect(() => {
    getCourseSearchData(details.data);
    getTestSearchData(tests.data);
  }, [search]);

  const getCourseSearchData = (data: Array<CourseResponse>) =>
    setShowCourses(
      data.filter((item) =>
        SearchLocal(search.toLowerCase(), [
          item.title.toLowerCase(),
          item.description.toLowerCase(),
        ])
      )
    );

  const getTestSearchData = (data: Array<TestResponse>) =>
    setShowTests(
      data.filter((item) =>
        SearchLocal(search.toLowerCase(), [
          item.name.toLowerCase(),
          item.description.toLowerCase(),
          item.instructions.toLowerCase(),
          item.duration.toString(),
        ])
      )
    );

  const getCourseTopic = (course: CourseResponse) => {
    dispatch(
      Main.postPaginatedPageObjectSuccess({
        stateKey: StateKeys.CLASS_COURSE_TOPICS,
        data: {},
        clear: true,
      })
    );
    dispatch(
      Main.postPaginatedPageDataSuccess({
        stateKey: StateKeys.CLASS_COURSE_TOPICS,
        data: [],
        clear: true,
      })
    );
    if (course.total_topics > 0) {
      if (
        dataPersist.data &&
        dataPersist.data[
          StateKeys.CLASS_COURSE_TOPICS.concat("-", String(course.id))
        ]
      ) {
        dispatch(
          Main.postPaginatedPageDataSuccess({
            stateKey: StateKeys.CLASS_COURSE_TOPICS,
            data: dataPersist.data[
              StateKeys.CLASS_COURSE_TOPICS.concat("-", String(course.id))
            ].data,
            clear: true,
          })
        );
        dispatch(
          Main.togglePageState({
            stateKey: StateKeys.CLASS_COURSE_TOPICS,
            data: dataPersist.data[
              StateKeys.CLASS_COURSE_TOPICS.concat("-", String(course.id))
            ].pageState,
          })
        );
      } else {
        dispatch(
          Main.getPaginatedPageData({
            stateKey: StateKeys.CLASS_COURSE_TOPICS,
            apiEndpoint: ApiEndpoint.COURSE_TOPICS(Number(course.id)),
            previousPage: 0,
            obj: true,
            storeKey: StateKeys.CLASS_COURSE_TOPICS.concat(
              "-",
              String(course.id)
            ),
          })
        );
      }
    }
  };

  const getTestResults = (test: TestResponse) => {
    dispatch(
      Main.postPageArrayDataSuccess({
        stateKey: StateKeys.CLASS_TEST_RESULTS,
        data: {},
        clear: true,
      })
    );
    dispatch(
      Main.postPageArrayDataSuccess({
        stateKey: StateKeys.CLASS_TEST_RESULTS,
        data: [],
        clear: true,
      })
    );
    if (
      dataPersist.data &&
      dataPersist.data[
        StateKeys.CLASS_TEST_RESULTS.concat("-", String(test.id))
      ]
    ) {
      dispatch(
        Main.postPageArrayDataSuccess({
          stateKey: StateKeys.CLASS_TEST_RESULTS,
          data: dataPersist.data[
            StateKeys.CLASS_TEST_RESULTS.concat("-", String(test.id))
          ].data,
          clear: true,
        })
      );
      dispatch(
        Main.togglePageState({
          stateKey: StateKeys.CLASS_TEST_RESULTS,
          data: dataPersist.data[
            StateKeys.CLASS_TEST_RESULTS.concat("-", String(test.id))
          ].pageState,
        })
      );
    } else {
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys.CLASS_TEST_RESULTS,
          apiEndpoint: ApiEndpoint.CLASS_TEST_RESULTS(Number(test.id)),
          storeKey: StateKeys.CLASS_COURSE_TOPICS.concat("-", String(test.id)),
        })
      );
    }
  };

  useEffect(() => {
    if (subscription) {
      setHasSubscribed(true);
    }
  }, [subscriptions]);

  const subscribe = (classId: string | number | null) => {
    const subscribed = subscription
      ? subscription.is_subscribed
      : details.obj?.is_subscribed;

    subscribed
      ? dispatch(
          Main.postPageData({
            stateKey: StateKeys.USER_SUBSCRIPTION,
            apiEndpoint: ApiEndpoint.UNSUBSCRIBE_CLASS(Number(classId ? classId : getClassId())),
            req: "",
            removeAt: subscription
              ? subscriptions.data.indexOf(subscription)
              : undefined,
          })
        )
      : dispatch(
          Main.postPageData({
            stateKey: StateKeys.USER_SUBSCRIPTION,
            apiEndpoint: ApiEndpoint.SUBSCRIBE_CLASS(Number(getClassId())),
            req: "",
            append: "start",
          })
        );

      window.location.reload();
  };

  return (
    <div
      className="content-page scroller-hide"
      onScroll={(e) => AppPageScrollDownLoadMore(e, loadMore)}
    >
      <GoBack previousPage={setShowCourseDetails} />
      {showCourseDetails ? (
        <ViewCourse
          subscriptionState={subscriptions.pageState!}
          hasSubscribed={hasSubscribed!}
          subscribeFunction={() => subscribe(Number(getClassId()))}
          topics={topics}
          topic={showTopicDetails!}
          showCourseDetails={showCourseDetails!}
          setShowTopicDetails={setShowTopicDetails}
          hasPaid={details.obj?.has_paid}
          paymentUrl={details.obj?.payment_url}
        />
      ) : (
        <Row>
          {(details.pageState === AppState.LOADED ||
            details.pageState === AppState.LOADING_MORE) && (
            <>
              {details.data ? (
                CheckContentProviderIdExist() &&
                Number(getContentProviderId()) !==
                  details.obj?.content_provider_id ? (
                  <div className="text-center pt-5 mt-5">
                    <h5 className="pb-3">{getLanguage.sorryYouCannot}</h5>
                    <a
                      href={`web.ecampus.camp/classes/${getClassId()}/courses`}
                      target="_blank"
                      className="text-decoration-none btn btn-primary"
                      rel="noreferrer"
                    >
                      {getLanguage.clickHereToAccessContent}
                    </a>
                  </div>
                ) : (
                  <div className="d-flex flex-column gap-5">
                    <Col sm={12}>
                      <Card className="course-card-hero d-flex align-items-center justify-content-center">
                        <img
                          src={activeClass?.data?.logo_url}
                          alt="story-img"
                        />
                      </Card>

                      <div className="course-details">
                        <div className="w-100">
                          <div className="d-flex flex-column gap-4">
                            <div className="d-grid d-md-flex  justify-content-md-between gap-3 pb-2 gap-md-0 pb-md-0">
                              <h5
                                style={{
                                  fontSize: "20px",
                                  fontWeight: 600,
                                }}
                              >
                                {activeClass?.data?.name}
                              </h5>

                              {!CheckContentProviderIdExist() &&

                                  (
                                      ecampus_id ?
                                  (
                                <>
                                  <Button
                                    type="button"
                                    className="btn btn-primary rounded"
                                    onClick={() => subscribe(activeClass?.data?.id)}
                                    disabled={
                                      subscriptions.pageState ===
                                      AppState.LOADING
                                    }
                                  >
                                    {subscriptions.pageState ===
                                      AppState.LOADING && (
                                      <Spinner
                                        animation={"border"}
                                        size={"sm"}
                                        className="me-1"
                                      />
                                    )}
                                    {subscription ? (
                                      <>
                                        {subscription.is_subscribed
                                          ? getLanguage.unSubscribe
                                          : getLanguage.subscribe}
                                      </>
                                    ) : (
                                      <>
                                        {details.obj?.is_subscribed
                                          ? getLanguage.unSubscribe
                                          : getLanguage.subscribe}
                                      </>
                                    )}
                                  </Button>
                                </>
                                  )
                                          :
                                  (
                                      <a
                                        href={RouteEndpoints.SIGN_IN}
                                        className="text-decoration-none btn btn-primary"
                                        rel="noreferrer"
                                      >
                                        {getLanguage.clickHereToAccessContent}
                                      </a>

                                  )
                                  )


                              }
                            </div>

                            <div className="d-flex gap-4 justify-content-start align-items-start gap-sm-5 course-sub-details">
                              <div
                                className="d-flex align-items-center justify-content-center gap-2"
                                style={{
                                  fontSize: "11px",
                                }}
                              >
                                <svg
                                  width="15"
                                  height="17"
                                  viewBox="0 0 15 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14 16.0001V14.3334C14 13.4493 13.6576 12.6015 13.0481 11.9763C12.4386 11.3512 11.612 11 10.75 11H4.25C3.38805 11 2.5614 11.3512 1.9519 11.9763C1.34241 12.6015 1 13.4493 1 14.3334V16.0001"
                                    stroke="#9E9E9E"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M7.5 7.66674C9.29493 7.66674 10.75 6.17434 10.75 4.33337C10.75 2.4924 9.29493 1 7.5 1C5.70507 1 4.25 2.4924 4.25 4.33337C4.25 6.17434 5.70507 7.66674 7.5 7.66674Z"
                                    stroke="#9E9E9E"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>

                                {activeClass?.data?.content_provider?.name ??
                                  "NA"}
                              </div>

                              {/* <div
                              className="d-flex  align-items-center justify-content-center gap-2"
                              style={{
                                fontSize: "11px",
                              }}
                            >
                              <svg
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.90219 11.4222L7.5 11.1667L7.09781 11.4222L3.98551 13.3995L4.81994 9.61134L4.9131 9.18839L4.59336 8.89628L1.7613 6.309L5.4603 5.97325L5.91669 5.93182L6.08808 5.50681L7.5 2.00542L8.91192 5.50681L9.08331 5.93182L9.5397 5.97325L13.2366 6.30881L10.3996 8.89582L10.0788 9.18838L10.1728 9.61228L11.0117 13.3978L7.90219 11.4222Z"
                                  stroke="#9E9E9E"
                                  strokeWidth="1.5"
                                />
                              </svg>{" "}
                              {activeClass?.data?.rating ?? 0}/5
                            </div> */}

                              {/* <div
                                className="d-flex align-items-center justify-content-center gap-2"
                                style={{
                                  fontSize: "11px",
                                }}
                              >
                                <svg
                                  width="20"
                                  height="17"
                                  viewBox="0 0 20 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14.0909 16V14.3333C14.0909 13.4493 13.7461 12.6014 13.1323 11.9763C12.5186 11.3512 11.6862 11 10.8182 11H4.27273C3.40475 11 2.57231 11.3512 1.95856 11.9763C1.3448 12.6014 1 13.4493 1 14.3333V16"
                                    stroke="#9E9E9E"
                                    strokeWidth="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M7.54568 7.66667C9.35315 7.66667 10.8184 6.17428 10.8184 4.33333C10.8184 2.49238 9.35315 1 7.54568 1C5.7382 1 4.27295 2.49238 4.27295 4.33333C4.27295 6.17428 5.7382 7.66667 7.54568 7.66667Z"
                                    stroke="#9E9E9E"
                                    strokeWidth="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M19 16.0001V14.3334C18.9994 13.5948 18.7581 12.8774 18.3138 12.2937C17.8696 11.7099 17.2475 11.293 16.5454 11.1084M13.2727 1.1084C13.9767 1.29198 14.6006 1.70898 15.0462 2.29366C15.4918 2.87833 15.7337 3.59742 15.7337 4.33757C15.7337 5.07771 15.4918 5.7968 15.0462 6.38147C14.6006 6.96615 13.9767 7.38315 13.2727 7.56673"
                                    stroke="#9E9E9E"
                                    strokeWidth="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                {kFormatter(
                                  activeClass?.data?.total_subscribers
                                ) ?? "NA"}
                              </div> */}
                            </div>
                            <p>{activeClass?.data?.description ?? "NA"}</p>

                            <div className="course-card-details-container">
                              <Card className="d-flex justify-content-center align-items-center user-card p-1">
                                <small>Subscription per month</small>
                                <h6
                                  style={{ color: "#DA3732" }}
                                  className="font-weight-bold"
                                >
                                  {activeClass?.data?.subscription_currency}
                                  {activeClass?.data?.subscription_amount_month}
                                </h6>
                              </Card>
                              <Card className="d-flex justify-content-center align-items-center user-card p-1">
                                <small>Category</small>
                                <h6>
                                  {activeClass?.data?.category?.name ?? "NA"}
                                </h6>
                              </Card>
                              <Card className="d-flex justify-content-center align-items-center user-card p-1">
                                <small>Language</small>
                                <h6>{activeClass?.data?.language ?? "NA"}</h6>
                              </Card>
                              {/* <Card className="d-flex justify-content-center align-items-center user-card p-1">
                                <small>Last Updated</small>
                                <h6>
                                  {new Date(
                                    activeClass?.data?.updated_at ?? ""
                                  ).toLocaleDateString(
                                    getLanguage.dateTimeLanguage,
                                    {
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    }
                                  )}
                                </h6>
                              </Card> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {/* <Col sm={12}>
                    <div className="d-flex justify-content-end mb-3">
                      <div className="iq-search-bar device-search">
                        <div className="searchbox">
                          <span className="search-link">
                            <i className="ri-search-line text-primary" />
                          </span>
                          <input
                            type="text"
                            className="text search-input bg-white"
                            placeholder={getLanguage.searchHere}
                            defaultValue={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </Col> */}
                    <Col md={12} className="content-card p-4 overflow-hidden">
                      <Tab.Container
                        defaultActiveKey={tab.length ? tab : "courses"}
                      >
                        <div className="user-tabing">
                          <Nav
                            as="ul"
                            variant="pills"
                            className="d-flex align-items-center justify-content-start course-tab p-0 m-0"
                          >
                            <Nav.Item
                              as="li"
                              className=" col-12 col-sm-2 p-0  "
                            >
                              <Nav.Link
                                eventKey="courses"
                                role="button"
                                className="text-center p-0 m-0"
                              >
                                {getLanguage.courses}{" "}
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="col-12 col-sm-2 p-0">
                              <Nav.Link
                                eventKey="tests"
                                role="button"
                                className="text-center p-0 m-0"
                              >
                                {getLanguage.test}{" "}
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <hr />
                        </div>

                        <Col sm={12} className="tab-content-container">
                          <Tab.Content>
                            <Tab.Pane eventKey="courses">
                              <Row>
                                {details.data.length > 0 ? (
                                  showCourses.length > 0 ? (
                                    <CourseCardItems
                                      courses={showCourses}
                                      setShowCourseDetails={
                                        setShowCourseDetails
                                      }
                                      getCourseTopic={getCourseTopic}
                                    />
                                  ) : (
                                    <NoData title="noCourses" />
                                  )
                                ) : (
                                  <NoData title="noCourses" />
                                )}

                                <Col md={12}>
                                  {details.pageState ===
                                    AppState.LOADING_MORE && (
                                    <Loader noCard imageLoader />
                                  )}
                                </Col>
                              </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tests">
                              <Row>
                                {(tests.pageState === AppState.LOADED ||
                                  tests.pageState ===
                                    AppState.LOADING_MORE) && (
                                  <>
                                    {tests.data.length > 0 ? (
                                      showTests?.length > 0 ? (
                                        <TestsCardList
                                          tests={showTests}
                                          setShowTestDetails={null!}
                                          getTestResults={null!}
                                        />
                                      ) : (
                                        <NoData title="noTest" />
                                      )
                                    ) : (
                                      <NoData title="noTest" />
                                    )}
                                  </>
                                )}
                                <Col md={12}>
                                  {(tests.pageState === AppState.LOADING_MORE ||
                                    tests.pageState === AppState.LOADING) && (
                                    <Loader noCard imageLoader />
                                  )}
                                </Col>
                              </Row>
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Tab.Container>
                    </Col>
                  </div>
                )
              ) : (
                <NoData
                  title="classNotFound"
                  link={RouteEndpoints.HOME}
                  btnText="backHome"
                />
              )}
            </>
          )}
          <Col md={12}>
            {details.hasMore &&
              !(
                details.pageState === AppState.LOADING ||
                details.pageState === AppState.LOADING_MORE
              ) && (
                <div
                  className={`w-100 text-center shadow-xss rounded-xxl border-0 px-3`}
                >
                  <button
                    className="text-decoration-none btn btn-primary"
                    onClick={() => loadMore()}
                  >
                    {getLanguage.loadMore}
                  </button>
                </div>
              )}
          </Col>
          <Col md={12}>
            {(details.pageState === AppState.LOADING ||
              details.pageState === AppState.LOADING_MORE) && (
              <Loader noCard imageLoader />
            )}
          </Col>
          {details.pageState === AppState.ERROR_404 && (
            <NoData
              title="classNotFound"
              link={RouteEndpoints.HOME}
              btnText="backHome"
            />
          )}
        </Row>
      )}
    </div>
  );
}

export default ClassCourses;
