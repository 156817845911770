import React from "react";
import { AppState } from "../../services/app.service";
import Loader from "../Loader";
import NoData from "../../pages/main/NoData";
import { Accordion, Card, Col, Nav, Row } from "react-bootstrap";
import Languages from "../Languages";
import { BaseState, StateKeys } from "../../redux/states/main.state";
import { RouteEndpoints } from "../../services/route.service";
import {
  ClassResponse,
  CourseResponse,
} from "../../services/api.response.service";

interface props {
  courses: BaseState<Array<CourseResponse>, ClassResponse>;
  courseTab: string;
  handleSetCourse: (id: string) => void;
  getCourseTopic: (data: BaseState) => CourseResponse[];
  title: string;
  contentId: number;
  dataPersist: BaseState<any, any>;
}

const CourseOutline = ({
  courses,
  courseTab,
  handleSetCourse,
  getCourseTopic,
  title,
  contentId,
  dataPersist,
}: props) => {
  return (
    <>
      <Card className="course-outline">
        <Card.Header className="text-dark border-0">
           <Languages langKey="courseOutline" />
          {/*<h6 className="fw-bold"> {title}</h6>{" "}*/}
        </Card.Header>
        <Card.Body className="p-0">
          <Row className="p-0 m-0">
            <Col md={12}>
              {/* <small>Topics:</small> */}

              {(courses.pageState === AppState.LOADED ||
                courses.pageState === AppState.LOADING_MORE) && (
                <>
                  {courses.data.length > 0 ? (
                    <Nav
                      variant="pills"
                      className="content-page basic-info-items list-inline d-block p-0 m-0"
                    >
                      <Accordion defaultActiveKey={courseTab}>
                        {courses.data.map(
                          (
                            { id, title, total_topics, has_read, has_practice },
                            i
                          ) =>
                            total_topics > 0 ? (
                              <div className="d-flex align-items-center position-relative outline-container">
                                <Accordion.Item
                                  eventKey={StateKeys.COURSE_CONTENT.concat(
                                    "-",
                                    String(id)
                                  )}
                                  key={i}
                                >
                                  <div className="d-flex align-items-center">
                                    {/*<i className="bi bi-diamond"></i>{" "}*/}
                                    <i className="fa fa-circle"></i>{" "}
                                    <Accordion.Header className="fs-7 fw-bold">
                                       {title}
                                    </Accordion.Header>
                                  </div>

                                  <Accordion.Body>
                                    {(has_read || has_practice) && (
                                      <Nav.Item
                                        key={i}
                                        onClick={() =>
                                          handleSetCourse(String(id))
                                        }
                                      >
                                        <Nav.Link
                                          eventKey={StateKeys.COURSE_CONTENT.concat(
                                            "-",
                                            String(id)
                                          )}
                                        >
                                          <small className="fs-8 fw-bold">
                                            {title}
                                          </small>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                    {getCourseTopic(
                                      dataPersist.data[
                                        StateKeys.CLASS_COURSE_TOPICS.concat(
                                          "-",
                                          String(id)
                                        )
                                      ]
                                    )
                                      .filter(
                                        (e) => e.has_practice || e.has_read
                                      )
                                      .map(({ id, title, total_topics }, i) => (
                                        <Nav.Item
                                          key={i}
                                          onClick={() =>
                                            handleSetCourse(String(id))
                                          }
                                          className="ms-4 text-black cursor-pointer"
                                        >
                                          <Nav.Link
                                            eventKey={StateKeys.COURSE_CONTENT.concat(
                                              "-",
                                              String(id)
                                            )}
                                          >

                                            <small
                                              style={
                                              {
                                                fontSize:  id==contentId ? "2.875rem" : "0.875rem",
                                                color: 'black',

                                              }
                                            }
                                            >
                                              {/*{i+1}.*/}
                                              {title}
                                            </small>
                                          </Nav.Link>
                                        </Nav.Item>
                                      ))}
                                  </Accordion.Body>
                                </Accordion.Item>{" "}
                              </div>
                            ) : (
                              <Nav.Item
                                style={{
                                  cursor: "pointer",
                                }}
                                key={i}
                                onClick={() => handleSetCourse(String(id))}
                              >
                                <div className="d-flex align-items-center">
                                  <i className="bi bi-diamond"></i>{" "}
                                  <Nav.Link
                                    eventKey={StateKeys.COURSE_CONTENT.concat(
                                      "-",
                                      String(id)
                                    )}
                                  >
                                    <small>{title}</small>
                                  </Nav.Link>
                                </div>
                              </Nav.Item>
                            )
                        )}
                      </Accordion>
                    </Nav>
                  ) : (
                    ""
                  )}
                </>
              )}
            </Col>
            <Col md={12}>
              {courses.pageState === AppState.LOADING && (
                <Loader noCard imageLoader />
              )}
            </Col>
            {courses.pageState === AppState.ERROR_404 && (
              <NoData
                title="classNotFound"
                link={RouteEndpoints.HOME}
                btnText="backHome"
              />
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default CourseOutline;
