import React, { useEffect } from "react";
import {
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  OAuthProvider,
} from "firebase/auth";

import { useAuthState } from "react-firebase-hooks/auth";

import Google from "../../assets/images/login/google.svg";
import Telecel from "../../assets/images/login/telecel.jpeg";
import { auth } from "../../services/firebase.service";
import { useDispatch } from "react-redux";
import Actions from "../../redux/actions";
import { useLocation } from "react-router";
import Languages from "../../components/Languages";
import { RouteEndpoints } from "../../services/route.service";
import { Link } from "react-router-dom";

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

const OAuth = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    user &&
      dispatch(
        Actions.auth.oAuth({
          email: user.email as unknown as string,
          firebase_uid: user?.uid,
        })
      );
  }, [user, loading, error]);

  const Oauth = async (type: string) => {
    type === "google" && (await signInWithPopup(auth, googleProvider));
    type === "apple" && (await signInWithPopup(auth, appleProvider));
    type === "facebook" && (await signInWithPopup(auth, facebookProvider));
  };

  return (
    <>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-evenly align-items-center">
          <hr className="px-4 px-sm-5 px-lg-4" />
          <Languages langKey="or" />
          <hr className="px-4 px-sm-5 px-lg-4" />
        </div>

        {location.pathname !== RouteEndpoints.SIGN_UP &&
          location.pathname !== RouteEndpoints.SIGN_IN && (
            <Link
              to={RouteEndpoints.SIGN_IN}
              className="card* rounded-pill* p-2* btn btn-outline-dark d-flex gap-2 justify-content-center align-items-center mb-2"
            >
              <Languages langKey="signInUsernameEmailOthers" />
            </Link>
          )}
        <button
          type="button"
          style={{ cursor: "pointer" }}
          onClick={() => Oauth("google")}
          className="card* rounded-pill* p-2* btn btn-outline-primary d-flex gap-2 justify-content-center align-items-center"
        >
          <img src={Google} alt="google" style={{ width: "20px" }} />
          {location.pathname === RouteEndpoints.SIGN_UP ? (
            <Languages langKey="signUpGoogle" />
          ) : (
            <Languages langKey="signInGoogle" />
          )}{" "}
        </button>
        {
          // location.pathname === RouteEndpoints.SIGN_UP &&
          location.pathname !== RouteEndpoints.TELE_SIGN_IN && (
            <Link
              to={RouteEndpoints.TELE_SIGN_IN}
              className="card* rounded-pill* p-2* btn btn-outline-dark d-flex gap-2 justify-content-center align-items-center mt-2"
            >
              <img src={Telecel} alt="Telecel" style={{ width: "20px" }} />
              <Languages langKey="signInTele" />
            </Link>
          )}
      </div>
    </>
  );
};
export default OAuth;
