import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Nav, Row, Spinner, Tab } from "react-bootstrap";
// import Card from "../../components/Card";
import { RouteEndpoints } from "../../services/route.service";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import { BaseState, StateKeys } from "../../redux/states/main.state";
import {
  AppState,
  CheckContentProviderIdExist,
  getContentProviderId,
  getLanguageByDefault,
  ResourceTypes,
} from "../../services/app.service";
import Main from "../../redux/actions/main.action";
import ApiEndpoint from "../../services/api.endpoint.service";
import { RouteComponentProps, useParams } from "react-router";
import Loader from "../../components/Loader";
import NoData from "./NoData";
import {
  ClassContentDiscussionResponse,
  ClassResponse,
  ContentResponse,
  CourseResponse,
  ResourceResponse,
  SubscriptionResponse,
  TestResponse,
  TestResultResponse,
} from "../../services/api.response.service";
import { baseURL } from "../../services/api.service";
import { Link } from "react-router-dom";
import TestsCardList from "../../components/TestsCardList";
import TestOffCanvas from "../../components/TestOffCanvas";
import {
  CalculateUserMediaPoints,
  CalculateUserReadPoints,
} from "../../utils/extraFunctions";
import Languages from "../../components/Languages";
import AudioPlayer from "../../components/AudioPlayer";

import GoBack from "../../components/GoBack";
import CourseOutline from "../../components/classCourse/CourseOutline";
import Discussions from "../../components/classCourse/Disccussions";

function CourseContent({ location }: RouteComponentProps) {
  // const id = location.pathname.replace(RouteEndpoints.COURSE_CONTENT(""), "");
  // const tab = location.hash.replace('#','')
  let { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);

  const dataPersist = state[StateKeys.DATA_PERSIST];
  const details: BaseState<ContentResponse> = state[StateKeys.COURSE_CONTENT];
  const courses: BaseState<Array<CourseResponse>, ClassResponse> = state[
    StateKeys.CLASS_COURSES
  ];
  const discussions: BaseState<Array<ClassContentDiscussionResponse>> =
    state[StateKeys.CLASS_CONTENT_DISCUSSION];

  const subscriptions: BaseState<Array<SubscriptionResponse>> =
    state[StateKeys.USER_SUBSCRIPTION];
  const testResults: BaseState<Array<TestResultResponse>> =
    state[StateKeys.CLASS_TEST_RESULTS];

  const video = state[StateKeys.VIDEO];
  const videoRef = useRef<HTMLVideoElement>(null);
  const audio = state[StateKeys.AUDIO];

  const [courseId, setCourseId] = useState(id);
  const [courseTab, setCourseTab] = useState(
    StateKeys.COURSE_CONTENT.concat("-", id)
  );

  const [showTests, setShowTests] = useState<TestResponse[]>([]);
  const [showTestDetails, setShowTestDetails] = useState<TestResponse>();

  // const [hasSubscribed, setHasSubscribed] = useState<boolean>(true);

  const [giveUserPoint, setGiveUserPoint] = useState<boolean>(false);
  const [getUserPointType, setGetUserPointType] = useState<string>();
  const [isUserMouseOnCard, setIsUserMouseOnCard] = useState<boolean>(false);
  const [isUserScrollingCard, setIsUserScrollingCard] =
    useState<boolean>(false);
  const getLanguage = getLanguageByDefault();

  const subscription =
    subscriptions.data.length > 0
      ? subscriptions.data.find((e) => String(e.subscriber_class.id) === id)
      : undefined;

  const loadCourseContent = (id: string) => {
    if (
      details.pageState !== AppState.LOADED ||
      (details.pageState === AppState.LOADED && String(details.data.id) !== id)
    ) {
      if (
        // dataPersist.data &&
        // dataPersist.data[StateKeys.COURSE_CONTENT.concat("-", id)]
          false
      ) {
        dispatch(
          Main.postPageObjectDataSuccess({
            stateKey: StateKeys.COURSE_CONTENT,
            data: dataPersist.data[StateKeys.COURSE_CONTENT.concat("-", id)]
              .data,
            clear: true,
          })
        );
        dispatch(
          Main.togglePageState({
            stateKey: StateKeys.COURSE_CONTENT,
            data: dataPersist.data[StateKeys.COURSE_CONTENT.concat("-", id)]
              .pageState,
          })
        );
        dispatch(
          Main.setPageMessage({
            stateKey: StateKeys.COURSE_CONTENT,
            data: dataPersist.data[StateKeys.COURSE_CONTENT.concat("-", id)]
              .message,
          })
        );
      } else {
        dispatch(
          Main.postPageObjectDataSuccess({
            stateKey: StateKeys.COURSE_CONTENT,
            data: {},
            clear: true,
          })
        );
        dispatch(
          Main.getPageObjectData({
            stateKey: StateKeys.COURSE_CONTENT,
            apiEndpoint: ApiEndpoint.COURSE_CONTENT(Number(id)),
            storeKey: StateKeys.COURSE_CONTENT.concat("-", id),
          })
        );
      }
    }
  };

  useEffect(() => {
    loadCourseContent(id);
  }, [id]);

  const getTopics = () => {
    courses.data.forEach((e) => {
      if (e.total_topics > 0) {
        if (
          !(
            dataPersist.data &&
            dataPersist.data[
              StateKeys.CLASS_COURSE_TOPICS.concat("-", String(e.id))
            ]
          )
        ) {
          dispatch(
            Main.getPaginatedPageData({
              stateKey: StateKeys.CLASS_COURSE_TOPICS,
              apiEndpoint: ApiEndpoint.COURSE_TOPICS(Number(e.id)),
              previousPage: 0,
              obj: true,
              storeKey: StateKeys.CLASS_COURSE_TOPICS.concat("-", String(e.id)),
              clear: true,
            })
          );
        }
      }
    });
  };

  useEffect(() => {
    if (discussions.pageState !== AppState.LOADED) {
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys.CLASS_CONTENT_DISCUSSION,
          apiEndpoint: ApiEndpoint.CONTENT_DISCUSSION(Number(id)),
          clear: true,
        })
      );
    }
  }, []);

  useEffect(() => {
      // console.log(details)
    if (details.pageState === AppState.LOADED) {
      // console.log(details.data.content_class.id)
      let id = String(details.data.content_class.id);
      if (
        courses.pageState !== AppState.LOADED ||
        (courses.pageState === AppState.LOADED &&
          String((courses.obj as ClassResponse).id) !== id)
      ) {
        // console.log(id, courses);
        if (
          courses.data.length > 0 &&
          String((courses.obj as ClassResponse).id) !== id
        ) {
          id = String((courses.obj as ClassResponse).id);
        }
        if (
          dataPersist.data &&
          dataPersist.data[StateKeys.CLASS_COURSES.concat("-", id)]
        ) {
          // console.log(id,'dataPersist',dataPersist.data[StateKeys.CLASS_COURSES.concat('-',id)])
          dispatch(
            Main.postPageObjectDataSuccess({
              stateKey: StateKeys.CLASS_COURSES,
              data: dataPersist.data[StateKeys.CLASS_COURSES.concat("-", id)]
                .obj,
              clear: true,
            })
          );
          dispatch(
            Main.postPaginatedPageDataSuccess({
              stateKey: StateKeys.CLASS_COURSES,
              data: dataPersist.data[StateKeys.CLASS_COURSES.concat("-", id)]
                .data,
              clear: true,
            })
          );
          dispatch(
            Main.togglePageState({
              stateKey: StateKeys.CLASS_COURSES,
              data: dataPersist.data[StateKeys.CLASS_COURSES.concat("-", id)]
                .pageState,
            })
          );
          // getTopics()
        } else {
          // console.log(id, "load");
          dispatch(
            Main.postPaginatedPageObjectSuccess({
              stateKey: StateKeys.CLASS_COURSES,
              data: {},
              clear: true,
            })
          );
          dispatch(
            Main.postPaginatedPageDataSuccess({
              stateKey: StateKeys.CLASS_COURSES,
              data: [],
              clear: true,
            })
          );
          dispatch(
            Main.getPaginatedPageData({
              stateKey: StateKeys.CLASS_COURSES,
              apiEndpoint: ApiEndpoint.CLASS_COURSES(Number(id)),
              previousPage: 0,
              obj: true,
              storeKey: StateKeys.CLASS_COURSES.concat("-", id),
            })
          );
        }
      }
    }

    setShowTests(details.data.tests);

    setGiveUserPoint(false);
  }, [details]);

  useEffect(() => {
    if (
      courses.hasMore &&
      courses.pageState !== AppState.LOADING_MORE &&
      courses.pageState === AppState.LOADED
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.CLASS_COURSES,
          apiEndpoint: ApiEndpoint.CLASS_COURSES(courses.obj!.id),
          previousPage: courses.currentPage!,
          storeKey: StateKeys.CLASS_COURSES.concat(
            "-",
            String(courses.obj!.id)
          ),
        })
      );
    }
    if (!courses.hasMore && courses.pageState === AppState.LOADED) {
      // setHasSubscribed(!!courses?.obj?.is_subscribed);
      getTopics();
    }
  }, [courses]);

  useEffect(() => {
    CalculateUserReadPoints({
      setGiveUserPoint,
      setIsUserMouseOnCard,
      setIsUserScrollingCard,
      isUserMouseOnCard,
      isUserScrollingCard,
      giveUserPoint,
    });
  });

  useEffect(() => {
    if (details.pageState === AppState.LOADED) {
      const req = video.data?.content_id
        ? {
            where: "watch",
            content_id: video.data.content_id,
            resource_id: video.data.id,
            points: 1,
          }
        : { where: "read", content_id: details.data.id, points: 1 };

      dispatch(
        Main.postPageData({
          stateKey: StateKeys.USER_POINTS,
          apiEndpoint: ApiEndpoint.POINTS,
          req,
        })
      );
    }
  }, [isUserMouseOnCard, giveUserPoint, isUserScrollingCard, getUserPointType]);

  const handleVideoPlaying = () => {
    dispatch(
      Main.togglePageState({
        stateKey: StateKeys.AUDIO,
        data: AppState.PAUSING,
      })
    );
    !!video.data.id && videoRef.current && videoRef.current.play();
    handleVideoState(AppState.PLAY);
  };

  const handleVideoPlay = (value: ResourceResponse) => {
    if (!video.data || (video.data as ResourceResponse).id !== value.id) {
      dispatch(
        Main.postPageObjectDataSuccess({
          stateKey: StateKeys.VIDEO,
          data: value,
          clear: true,
        })
      );
      dispatch(
        Main.togglePageState({
          stateKey: StateKeys.VIDEO,
          data: AppState.LOADING,
        })
      );
    } else {
      handleVideoState(AppState.PLAYING);
    }
  };

  const handleVideoPause = () => {
    // (videoRef as unknown as MutableRefObject<HTMLVideoElement>).current.pause()
    !!video.data.id && videoRef.current && videoRef.current.pause();
    handleVideoState(AppState.PAUSED);
  };

  const handleVideoState = (value: AppState) =>
    dispatch(Main.togglePageState({ stateKey: StateKeys.VIDEO, data: value }));

  useEffect(() => {
    if (video.pageState === AppState.PLAYING) {
      handleVideoPlaying();
    }

    if (
      video.pageState === AppState.PAUSING ||
      audio.pageState === AppState.PLAYING
    ) {
      handleVideoPause();
    }
  }, [audio, video]);

  const handleAudioPlay = (value: ResourceResponse) => {
    if (!audio.data || (audio.data as ResourceResponse).id !== value.id) {
      dispatch(
        Main.postPageObjectDataSuccess({
          stateKey: StateKeys.AUDIO,
          data: value,
          clear: true,
        })
      );
      dispatch(
        Main.togglePageState({
          stateKey: StateKeys.AUDIO,
          data: AppState.LOADING,
        })
      );
    } else {
      handleAudioState(AppState.PLAYING);
    }
  };

  const handleAudioState = (value: AppState) =>
    dispatch(Main.togglePageState({ stateKey: StateKeys.AUDIO, data: value }));

  const handleDefaultActiveKey = () =>
    details.data.has_read
      ? "read"
      : details.data.has_practice
      ? "practice"
      : details.data.content_resources.filter(
          (item) => item.type === ResourceTypes.VIDEO
        ).length
      ? "watch"
      : details.data.content_resources.filter(
          (item) => item.type === ResourceTypes.AUDIO
        ).length
      ? "listen"
      : details.data.has_test
      ? "test"
      : "discuss";

  const handleSetCourse = (id: string) => {
    handleVideoPause();

    dispatch(
      Main.getPageArrayData({
        stateKey: StateKeys.CLASS_CONTENT_DISCUSSION,
        apiEndpoint: ApiEndpoint.CONTENT_DISCUSSION(Number(id)),
        clear: true,
      })
    );
    dispatch(
      Main.postPageObjectDataSuccess({
        stateKey: StateKeys.VIDEO,
        data: {},
        clear: true,
      })
    );
    dispatch(
      Main.togglePageState({ stateKey: StateKeys.VIDEO, data: AppState.IDLE })
    );
    setCourseId(id);
    setCourseTab(StateKeys.COURSE_CONTENT.concat("-", id));
    loadCourseContent(id);
  };

  // const getDiscussionComments = (discuss: ClassContentDiscussionResponse) => {
  //   dispatch(
  //     Main.getPaginatedPageData({
  //       stateKey: StateKeys.CLASS_CONTENT_DISCUSSION_COMMENTS,
  //       apiEndpoint: ApiEndpoint.CONTENT_DISCUSSION_COMMENT(discuss.id),
  //       previousPage: 0,
  //       clear: true,
  //     })
  //   );
  //   setDiscussion(discuss);
  //   setOpenDiscussCanvas("comments");
  // };

  const subscribe = (class_id : number ) => {
    const subscribed = subscription
      ? subscription.is_subscribed
      : details.data.content_class?.is_subscribed ?? courses.obj?.is_subscribed;

    subscribed
      ? dispatch(
          Main.postPageData({
            stateKey: StateKeys.USER_SUBSCRIPTION,
            apiEndpoint: ApiEndpoint.UNSUBSCRIBE_CLASS(Number(class_id)),
            req: "",
            removeAt: subscription
              ? subscriptions.data.indexOf(subscription)
              : undefined,
          })
        )
      : dispatch(
          Main.postPageData({
            stateKey: StateKeys.USER_SUBSCRIPTION,
            apiEndpoint: ApiEndpoint.SUBSCRIBE_CLASS(Number(class_id)),
            req: "",
            append: "start",
          })
        );

      window.location.reload();
  };

  const getCourseTopic: (data: BaseState) => CourseResponse[] = (
    data: BaseState
  ) => (data ? (data.data as Array<CourseResponse>) : []);

  const getTestResults = (test: TestResponse) => {
    dispatch(
      Main.postPageArrayDataSuccess({
        stateKey: StateKeys.CLASS_TEST_RESULTS,
        data: {},
        clear: true,
      })
    );
    dispatch(
      Main.postPageArrayDataSuccess({
        stateKey: StateKeys.CLASS_TEST_RESULTS,
        data: [],
        clear: true,
      })
    );
    if (
      dataPersist.data &&
      dataPersist.data[
        StateKeys.CLASS_TEST_RESULTS.concat("-", String(test.id))
      ]
    ) {
      dispatch(
        Main.postPageArrayDataSuccess({
          stateKey: StateKeys.CLASS_TEST_RESULTS,
          data: dataPersist.data[
            StateKeys.CLASS_TEST_RESULTS.concat("-", String(test.id))
          ].data,
          clear: true,
        })
      );
      dispatch(
        Main.togglePageState({
          stateKey: StateKeys.CLASS_TEST_RESULTS,
          data: dataPersist.data[
            StateKeys.CLASS_TEST_RESULTS.concat("-", String(test.id))
          ].pageState,
        })
      );
    } else {
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys.CLASS_TEST_RESULTS,
          apiEndpoint: ApiEndpoint.CLASS_TEST_RESULTS(Number(test.id)),
          storeKey: StateKeys.CLASS_COURSE_TOPICS.concat("-", String(test.id)),
        })
      );
    }
  };

  return (
    <div className="content-page scroller-hide">
      <Row>
        {(details.pageState === AppState.LOADED ||
          details.pageState === AppState.LOADING_MORE ||
          courses.pageState === AppState.LOADED ||
          courses.pageState === AppState.LOADING_MORE) && (
          <>
            {details.pageState === AppState.LOADING ? (
              <Loader noCard imageLoader />
            ) : courses?.obj?.is_subscribed !== undefined ||
              details.data.content_class.is_subscribed ? (
              courses?.obj?.is_subscribed ||
              details.data.content_class.is_subscribed ? (
                courses?.obj?.has_paid ||
                details.data.content_class.has_paid ? (
                  <>
                    {CheckContentProviderIdExist() &&
                    Number(getContentProviderId()) !==
                      details.data.content_provider_id ? (
                      <div className="text-center pt-5 mt-5">
                        <h5 className="pb-3">{getLanguage.sorryYouCannot}</h5>
                        <Link
                          to={{
                            pathname: `https://web.ecampus.camp/content/${id}`,
                          }}
                          className="text-decoration-none btn btn-primary"
                          target="_blank"
                        >
                          {getLanguage.clickHereToAccessContent}
                        </Link>
                      </div>
                    ) : (
                      <Col sm={12}>
                        <Tab.Container defaultActiveKey={courseTab}>
                          <Row
                            className="overflow-hidden"
                            style={{ height: "90em" }}
                          >
                            <Col md={9} className="ps-4 ">
                              <GoBack />
                              <Col sm={12} className="mb-3">
                                  <Link
                                      style={{ color: "black" }}
                                      to={RouteEndpoints.CLASS_COURSES(
                                          String(
                                              details.pageState === AppState.LOADED ||
                                              details.pageState ===
                                              AppState.LOADING_MORE
                                                  ? details.data.content_class.id
                                                  : courses.obj!.id
                                          )
                                      )}
                                  >
                                      {details.pageState === AppState.LOADED ||
                                      details.pageState === AppState.LOADING_MORE
                                          ? details.data.content_class.name
                                          : courses.obj!.name}
                                  </Link>
                                <h3>
                                  {details.pageState === AppState.LOADED ||
                                  details.pageState === AppState.LOADING_MORE
                                    ? details.data.title
                                    : courses.data.find(
                                        (e) => e.id === Number(courseId)
                                      )?.title ?? "..."}
                                </h3>
                              </Col>
                              {(details.pageState === AppState.LOADED ||
                                details.pageState ===
                                  AppState.LOADING_MORE) && (
                                <Tab.Content>
                                  <Tab.Pane eventKey={courseTab}>
                                    <Tab.Container
                                      defaultActiveKey={handleDefaultActiveKey()}
                                    >
                                      {/* Read | Listen | Watch | Practice | Discuss
                                      | Tests */}
                                      <div className="user-tabing">
                                        <Nav
                                          as="ul"
                                          variant="pills"
                                          className="d-flex align-items-center justify-content-start course-tab p-0 m-0 border-bottom"
                                        >
                                          {details.data.has_read && (
                                            <Nav.Item
                                              as="li"
                                              className=" col-12 col-sm-2 p-0 "
                                            >
                                              <Nav.Link
                                                eventKey="read"
                                                role="button"
                                                className="text-center p-3"
                                                onClick={handleVideoPause}
                                              >
                                                <Languages langKey="read" />
                                              </Nav.Link>
                                            </Nav.Item>
                                          )}
                                          {!!details.data.content_resources.filter(
                                            (item) =>
                                              item.type === ResourceTypes.AUDIO
                                          ).length && (
                                            <Nav.Item
                                              as="li"
                                              className="col-12 col-sm-2 p-0"
                                            >
                                              <Nav.Link
                                                eventKey="listen"
                                                role="button"
                                                className="text-center p-3"
                                                onClick={handleVideoPause}
                                              >
                                                <Languages langKey="listen" />
                                              </Nav.Link>
                                            </Nav.Item>
                                          )}

                                          {!!details.data.content_resources.filter(
                                            (item) =>
                                              item.type === ResourceTypes.VIDEO
                                          ).length && (
                                            <Nav.Item
                                              as="li"
                                              className=" col-12 col-sm-2 p-0 "
                                            >
                                              <Nav.Link
                                                eventKey="watch"
                                                role="button"
                                                className="text-center p-3"
                                                onClick={() =>
                                                  handleVideoPlay(video.data)
                                                }
                                              >
                                                <Languages langKey="watch" />
                                              </Nav.Link>
                                            </Nav.Item>
                                          )}

                                          {details.data.has_practice && (
                                            <Nav.Item
                                              as="li"
                                              className="col-12 col-sm-2 p-0"
                                            >
                                              <Nav.Link
                                                eventKey="practice"
                                                role="button"
                                                className="text-center p-3"
                                                onClick={handleVideoPause}
                                              >
                                                <Languages langKey="practice" />
                                              </Nav.Link>
                                            </Nav.Item>
                                          )}

                                          <Nav.Item
                                            as="li"
                                            className="col-12 col-sm-2 p-0"
                                          >
                                            <Nav.Link
                                              eventKey="discuss"
                                              role="button"
                                              className="text-center p-3"
                                              onClick={handleVideoPause}
                                            >
                                              <Languages langKey="discuss" />
                                            </Nav.Link>
                                          </Nav.Item>

                                          {details.data.has_test && (
                                            <Nav.Item
                                              as="li"
                                              className="col-12 col-sm-2 p-0"
                                            >
                                              <Nav.Link
                                                eventKey="test"
                                                role="button"
                                                className="text-center p-3"
                                                onClick={handleVideoPause}
                                              >
                                                Test
                                              </Nav.Link>
                                            </Nav.Item>
                                          )}
                                        </Nav>
                                      </div>
                                      <Col sm={12} className="pt-3">
                                        <Tab.Content>
                                          {details.data.has_read && (
                                            <Tab.Pane
                                              eventKey="read"
                                              className="overflow-scroll"
                                              style={{ height: "72em" }}
                                            >
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    details.data.text_content,
                                                }}
                                              />
                                            </Tab.Pane>
                                          )}
                                          {details.data.has_practice && (
                                            <Tab.Pane
                                              eventKey="practice"
                                              className="content-page"
                                            >
                                              <Card className="h-100 w-100 overflow-hidden">
                                                <Card.Body className="p-3">
                                                  <iframe
                                                    src={details.data.practice_url.replace(
                                                      "/api/v4",
                                                      String(baseURL)
                                                    )}
                                                    frameBorder="0"
                                                    title={details.data.title}
                                                    className="w-100"
                                                    style={{ height: "64.2em" }}
                                                  />
                                                </Card.Body>
                                              </Card>
                                            </Tab.Pane>
                                          )}
                                          {!!details.data.content_resources.filter(
                                            (item) =>
                                              item.type === ResourceTypes.VIDEO
                                          ).length && (
                                            <Tab.Pane
                                              eventKey="watch"
                                              className="content-page"
                                            >
                                              {!!video.data.id && (
                                                <Card className="p-0">
                                                  <Card.Body className="p-3">
                                                    <video
                                                      id="video"
                                                      ref={videoRef}
                                                      className="w-100"
                                                      autoPlay
                                                      // loop
                                                      controls
                                                      controlsList="nodownload"
                                                      key={
                                                        (
                                                          video.data as ResourceResponse
                                                        ).id
                                                      }
                                                      onPause={() =>
                                                        handleVideoState(
                                                          AppState.PAUSING
                                                        )
                                                      }
                                                      onPlay={() => {
                                                        handleVideoState(
                                                          AppState.PLAYING
                                                        );
                                                        CalculateUserMediaPoints(
                                                          setGiveUserPoint,
                                                          "#video",
                                                          setGetUserPointType
                                                        );
                                                      }}
                                                      // onEnded={() => props.addPoint({ where: 'watch', contentId: course.content_id })}
                                                      onEnded={() =>
                                                        setGiveUserPoint(true)
                                                      }
                                                    >
                                                      <source
                                                        src={
                                                          (
                                                            video.data as ResourceResponse
                                                          ).url
                                                        }
                                                        type="video/mp4"
                                                        title={
                                                          (
                                                            video.data as ResourceResponse
                                                          ).description
                                                        }
                                                      />
                                                    </video>
                                                  </Card.Body>
                                                </Card>
                                              )}
                                              <Row>
                                                {details.data.content_resources
                                                  .filter(
                                                    (item) =>
                                                      item.type ===
                                                      ResourceTypes.VIDEO
                                                  )
                                                  .map((item, i) => (
                                                    <Col
                                                      key={i}
                                                      sm={6}
                                                      className="mt-3"
                                                    >
                                                      <Card>
                                                        <Card.Body>
                                                          <h6>
                                                            {item.description}
                                                          </h6>
                                                          <div className="mt-2 d-flex justify-content-between">
                                                            <span className="text-primary d-block line-height mt-0">
                                                              {(!video.data
                                                                .id ||
                                                                (
                                                                  video.data as ResourceResponse
                                                                ).id !==
                                                                  item.id ||
                                                                video.pageState ===
                                                                  AppState.PAUSED) && (
                                                                <i
                                                                  className={`las la-play fs-4 ${
                                                                    video.pageState ===
                                                                      AppState.LOADING &&
                                                                    "text-secondary"
                                                                  }`}
                                                                  onClick={() =>
                                                                    handleVideoPlay(
                                                                      item
                                                                    )
                                                                  }
                                                                />
                                                              )}
                                                              {!!video.data
                                                                .id &&
                                                                (
                                                                  video.data as ResourceResponse
                                                                ).id ===
                                                                  item.id &&
                                                                video.pageState ===
                                                                  AppState.PLAY && (
                                                                  <i
                                                                    className="las la-pause fs-4"
                                                                    onClick={() =>
                                                                      handleVideoPause()
                                                                    }
                                                                  />
                                                                )}
                                                            </span>
                                                            {!!video &&
                                                              (
                                                                video.data as ResourceResponse
                                                              ).id ===
                                                                item.id && (
                                                                <>
                                                                  {video.pageState ===
                                                                    AppState.LOADING && (
                                                                    <small className="text-secondary ms-auto">
                                                                      <Languages langKey="loading" />
                                                                    </small>
                                                                  )}
                                                                  {video.pageState ===
                                                                    AppState.PAUSED && (
                                                                    <small className="text-warning ms-auto">
                                                                      <Languages langKey="paused" />
                                                                    </small>
                                                                  )}
                                                                  {video.pageState ===
                                                                    AppState.PLAY && (
                                                                    <small className="text-success ms-auto">
                                                                      <Languages langKey="playing" />
                                                                    </small>
                                                                  )}
                                                                </>
                                                              )}

                                                            {/*{(!!video && !(videoRef as unknown as MutableRefObject<HTMLVideoElement>).current.paused) && <small className="text-success ms-auto">{(videoRef as unknown as MutableRefObject<HTMLVideoElement>).current.played}</small>}*/}
                                                          </div>
                                                        </Card.Body>
                                                      </Card>
                                                    </Col>
                                                  ))}
                                              </Row>
                                            </Tab.Pane>
                                          )}
                                          {!!details.data.content_resources.filter(
                                            (item) =>
                                              item.type === ResourceTypes.AUDIO
                                          ).length && (
                                            <Tab.Pane
                                              eventKey="listen"
                                              className="content-page"
                                            >
                                              <Row>
                                                {details.data.content_resources
                                                  .filter(
                                                    (item) =>
                                                      item.type ===
                                                      ResourceTypes.AUDIO
                                                  )
                                                  .map((item, i) => (
                                                    <Col
                                                      key={i}
                                                      sm={6}
                                                      className="mt-3"
                                                    >
                                                      <Card>
                                                        <Card.Body>
                                                          <h6>
                                                            {item.description}
                                                          </h6>
                                                          <div className="mt-2 d-flex justify-content-between">
                                                            <span className="text-primary d-block line-height mt-0">
                                                              {(!audio.data ||
                                                                (
                                                                  audio.data as ResourceResponse
                                                                ).id !==
                                                                  item.id ||
                                                                audio.pageState ===
                                                                  AppState.PAUSED) && (
                                                                <i
                                                                  className={`las la-play fs-4 ${
                                                                    video.pageState ===
                                                                      AppState.LOADING &&
                                                                    "text-secondary"
                                                                  }`}
                                                                  onClick={() =>
                                                                    handleAudioPlay(
                                                                      item
                                                                    )
                                                                  }
                                                                />
                                                              )}
                                                              {!!audio &&
                                                                (
                                                                  audio.data as ResourceResponse
                                                                ).id ===
                                                                  item.id &&
                                                                audio.pageState ===
                                                                  AppState.PLAY && (
                                                                  <i
                                                                    className="las la-pause fs-4"
                                                                    onClick={() =>
                                                                      handleAudioState(
                                                                        AppState.PAUSING
                                                                      )
                                                                    }
                                                                  />
                                                                )}
                                                            </span>
                                                            {!!audio &&
                                                              (
                                                                audio.data as ResourceResponse
                                                              ).id ===
                                                                item.id && (
                                                                <>
                                                                  {audio.pageState ===
                                                                    AppState.LOADING && (
                                                                    <small className="text-secondary ms-auto">
                                                                      <Languages langKey="loading" />
                                                                    </small>
                                                                  )}
                                                                  {audio.pageState ===
                                                                    AppState.PAUSED && (
                                                                    <small className="text-warning ms-auto">
                                                                      <Languages langKey="paused" />
                                                                    </small>
                                                                  )}
                                                                  {audio.pageState ===
                                                                    AppState.PLAY && (
                                                                    <small className="text-success ms-auto">
                                                                      <Languages langKey="playing" />
                                                                    </small>
                                                                  )}
                                                                </>
                                                              )}

                                                            {/*{(!!video && !(videoRef as unknown as MutableRefObject<HTMLVideoElement>).current.paused) && <small className="text-success ms-auto">{(videoRef as unknown as MutableRefObject<HTMLVideoElement>).current.played}</small>}*/}
                                                          </div>
                                                        </Card.Body>
                                                      </Card>
                                                    </Col>
                                                  ))}
                                              </Row>
                                              <AudioPlayer />
                                            </Tab.Pane>
                                          )}
                                          {details.data.has_test && (
                                            <Tab.Pane
                                              eventKey="test"
                                              className="content-page"
                                            >
                                              <Row>
                                                <>
                                                  {details.data.tests.length >
                                                  0 ? (
                                                    showTests?.length > 0 ? (
                                                      <TestsCardList
                                                        tests={
                                                          details.data.tests
                                                        }
                                                        setShowTestDetails={
                                                          setShowTestDetails
                                                        }
                                                        getTestResults={
                                                          getTestResults
                                                        }
                                                      />
                                                    ) : (
                                                      <NoData title="noTest" />
                                                    )
                                                  ) : (
                                                    <NoData title="noTest" />
                                                  )}
                                                </>
                                              </Row>
                                            </Tab.Pane>
                                          )}

                                          <Tab.Pane
                                            eventKey="discuss"
                                            className="content-page"
                                          >
                                            <Card
                                              className="discussCard position-relative"
                                              id="discuss"
                                            >
                                              <Discussions
                                                discussions={discussions}
                                                contentID={details?.data?.id}
                                              />
                                            </Card>
                                          </Tab.Pane>
                                        </Tab.Content>
                                      </Col>
                                    </Tab.Container>
                                  </Tab.Pane>
                                </Tab.Content>
                              )}

                              {details.pageState === AppState.ERROR_404 && (
                                <NoData
                                  title="classNotFound"
                                  link={RouteEndpoints.HOME}
                                  btnText="backHome"
                                />
                              )}
                            </Col>
                            <Col md={3} className="d-none d-md-block">
                              <CourseOutline
                                courses={courses}
                                courseTab={courseTab}
                                handleSetCourse={handleSetCourse}
                                getCourseTopic={getCourseTopic}
                                dataPersist={dataPersist}
                                title={details?.data?.title}
                                contentId={details?.data?.id}
                              />
                            </Col>
                          </Row>
                        </Tab.Container>
                      </Col>
                    )}
                  </>
                ) : (
                  <div className="text-center pt-5 mt-5">
                    <h5 className="pb-3">{getLanguage.payToAccessContent}</h5>
                    <a
                      href={
                        details.data.content_class.payment_url ??
                        courses?.obj?.payment_url
                      }
                      target="_blank"
                      className="text-decoration-none btn btn-primary"
                      rel="noreferrer"
                    >
                      {getLanguage.payNow}
                    </a>
                  </div>
                )
              ) : (
                <div className="text-center pt-5 mt-5">
                  <h5 className="pb-3">
                    <Languages langKey="subscribeToView" />
                  </h5>
                  <Button
                    type="button"
                    className="btn btn-primary rounded px-5"
                    onClick={() =>
                      CheckContentProviderIdExist() ? null : subscribe(details.data.content_class.id ??
                          courses?.obj?.id)
                    }
                    disabled={
                      subscriptions.pageState === AppState.LOADING ||
                      CheckContentProviderIdExist()
                    }
                  >
                    {subscriptions.pageState === AppState.LOADING && (
                      <Spinner
                        animation={"border"}
                        size={"sm"}
                        className="me-1"
                      />
                    )}

                    {CheckContentProviderIdExist() ? (
                      <Languages langKey="contentAdminCourse" />
                    ) : (
                      <Languages langKey="subscribe" />
                    )}
                  </Button>
                </div>
              )
            ) : (
              <Loader noCard imageLoader />
            )}
          </>
        )}
        <Col md={12}>
          {details.pageState === AppState.LOADING &&
            courses.pageState !== AppState.LOADED && (
              <Loader noCard imageLoader />
            )}
        </Col>
      </Row>

      <TestOffCanvas
        results={testResults}
        hasSubscribed={true}
        showTestDetails={showTestDetails!}
        setShowTestDetails={setShowTestDetails}
      />
    </div>
  );
}

export default CourseContent;
