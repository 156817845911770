import { RouteEndpoints } from "./route.service";
import constants from "../utils/constants";
import { AuthResponse, UserResponse } from "./api.response.service";
import { useSelector } from "react-redux";
import { IRootState } from "../redux/states";
import en from "../languages/en";
import fr from "../languages/fr";
import ReactGA from "react-ga";

const postFix = process.env.REACT_APP_ENV === "production" ? "" : "_dev";

export const getKey = (key: string) => `${key}${postFix}`;

export const DEFAULT_TIMER = 10000;

export const AppSettings = () => {
  return {
    APP_NAME: "eCampus",
    APP_ICON: "logo.png",
    APP_SHORT_NAME: "eC",
    AUTH_BACKGROUND: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${
      CheckAuthImageExist()
        ? getAuthImage()
        : "https://playground.ecampus.camp/v3/img/login-bg/bg-7.jpg"
    }) center no-repeat`,
  };
};

export const runAppSettingsSetup = () => {
  document.title = AppSettings().APP_NAME;

  /*const signInPage = document.querySelector('.sign-in-page')
    if(signInPage && AppSettings().AUTH_BACKGROUND_IMAGE){
        // @ts-ignore
        signInPage.style.backgroundImage = `url(${AppSettings().AUTH_BACKGROUND_IMAGE})`
    }*/
};

export interface HomeName {
  fr: string;
  eng: string;
}

interface SidebarMenuItem {
  name: string;
  icon: string;
  link: string;
  svg: string;
}

export const CheckContentProviderIdExist = () =>
  !!process.env.REACT_APP_CONTENT_PROVIDER_ID;
export const CheckInstitutionsExist = () =>
  !!process.env.REACT_APP_INSTITUTIONS;
export const CheckSignUpReferralExist = () =>
  !!process.env.REACT_APP_SIGN_UP_REFERRAL;
export var CheckAuthImageExist = () => !!process.env.REACT_APP_AUTH_IMAGE;
export var CheckAuthSliderTextsExist = () =>
  !!process.env.REACT_APP_AUTH_SLIDER_TEXT;
export var CheckAppNameExist = () => !!process.env.REACT_APP_APP_NAME;
export var CheckAppShortNameExist = () =>
  !!process.env.REACT_APP_APP_SHORT_NAME;
export var CheckAppLogoExist = () => !!process.env.REACT_APP_APP_LOGO;
export var CheckAppFaviconExist = () => !!process.env.REACT_APP_APP_FAVICON;

export const AppSidebarMenu = (): Array<SidebarMenuItem> => {
  let menu = [
    {
      name: getLanguageByDefault().home,
      icon: "las la-home",
      svg: "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
          "<path d=\"M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z\" stroke=\"#BB1C17\" stroke-width=\"2.46471\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
          "<path d=\"M9 22V12H15V22\" stroke=\"#BB1C17\" stroke-width=\"2.46471\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
          "</svg>\n",
      link: RouteEndpoints.HOME,
    },
  ];
  if (CheckContentProviderIdExist()) {
    menu = [
      ...menu,
      {
        name: getLanguageByDefault().subscriptions,
        icon: "las la-folder",
        svg: "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
            "<path d=\"M2 17L12 22L22 17\" stroke=\"#3C3C3B\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
            "<path d=\"M2 12L12 17L22 12\" stroke=\"#3C3C3B\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
            "<path d=\"M12 2L2 7L12 12L22 7L12 2Z\" stroke=\"#3C3C3B\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
            "</svg>\n",
        link: RouteEndpoints.SUBSCRIPTIONS,
      },
      {
        name: getLanguageByDefault().classes,
        icon: "las la-anchor",
        svg: "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
            "<path d=\"M2 17L12 22L22 17\" stroke=\"#3C3C3B\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
            "<path d=\"M2 12L12 17L22 12\" stroke=\"#3C3C3B\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
            "<path d=\"M12 2L2 7L12 12L22 7L12 2Z\" stroke=\"#3C3C3B\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
            "</svg>\n",
        link: RouteEndpoints.CONTENT_PROVIDER_CLASSES,
      },
      /*{
        name: getLanguageByDefault().forum,
        icon: "las la-comments",
        link: RouteEndpoints.FORUM,
      },*/
    ];
  } else {
    menu = [
      ...menu,
      {
        name: getLanguageByDefault().contentProviders,
        icon: "las la-users",
        svg: "<svg width=\"25\" height=\"25\" viewBox=\"0 0 25 25\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
            "<path d=\"M20.166 21.641V19.641C20.166 18.5802 19.7446 17.5627 18.9944 16.8126C18.2443 16.0624 17.2269 15.641 16.166 15.641H8.16602C7.10515 15.641 6.08773 16.0624 5.33759 16.8126C4.58744 17.5627 4.16602 18.5802 4.16602 19.641V21.641\" stroke=\"#3C3C3B\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
            "<path d=\"M12.168 11.6407C14.3771 11.6407 16.168 9.84982 16.168 7.64069C16.168 5.43155 14.3771 3.64069 12.168 3.64069C9.95883 3.64069 8.16797 5.43155 8.16797 7.64069C8.16797 9.84982 9.95883 11.6407 12.168 11.6407Z\" stroke=\"#3C3C3B\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
            "</svg>\n",
        link: RouteEndpoints.CONTENT_PROVIDERS,
      },
      {
        name: getLanguageByDefault().subscriptions,
        icon: "las la-folder",
        svg: "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
            "<path d=\"M2 17L12 22L22 17\" stroke=\"#3C3C3B\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
            "<path d=\"M2 12L12 17L22 12\" stroke=\"#3C3C3B\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
            "<path d=\"M12 2L2 7L12 12L22 7L12 2Z\" stroke=\"#3C3C3B\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
            "</svg>\n",
        link: RouteEndpoints.SUBSCRIPTIONS,
      },
      /*{
              name: getLanguageByDefault().tests,
              icon: 'las la-home',
              link: RouteEndpoints.TESTS
          },*/

      {
        name: getLanguageByDefault().categories,
        icon: "ri-focus-2-line",
        svg: "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
            "<path d=\"M3 10H10V3H3V10Z\" stroke=\"#3C3C3B\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
            "<path d=\"M14 10H21V3H14V10Z\" stroke=\"#3C3C3B\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
            "<path d=\"M3 21H10V14H3V21Z\" stroke=\"#3C3C3B\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
            "<path d=\"M14 21H21V14H14V21Z\" stroke=\"#3C3C3B\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
            "</svg>\n",
        link: RouteEndpoints.CATEGORIES,
      },
      // {
      //   name: getLanguageByDefault().interests,
      //   icon: "las la-anchor",
      //   link: RouteEndpoints.INTERESTS,
      // },
    ];
  }

  menu = [
      ...menu,
      {
        name: getLanguageByDefault().events,
        icon: "las la-calendar-alt",
        svg: "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
            "<path d=\"M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z\" stroke=\"#2E2E2E\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
            "<path d=\"M3 10H21\" stroke=\"#2E2E2E\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
            "<path d=\"M16 2V6\" stroke=\"#2E2E2E\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
            "<path d=\"M8 2V6\" stroke=\"#2E2E2E\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
            "</svg>\n",
        link: RouteEndpoints.EVENTS,
      },
      {
        name: getLanguageByDefault().announcements,
        icon: "las la-bullhorn",
        svg: "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
            "<path d=\"M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z\" stroke=\"#2E2E2E\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
            "<path d=\"M13.7295 21C13.5537 21.3031 13.3014 21.5547 12.9978 21.7295C12.6941 21.9044 12.3499 21.9965 11.9995 21.9965C11.6492 21.9965 11.3049 21.9044 11.0013 21.7295C10.6977 21.5547 10.4453 21.3031 10.2695 21\" stroke=\"#2E2E2E\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
            "</svg>\n",
        link: RouteEndpoints.ANNOUNCEMENTS,
      },
  ];
  return menu;
};

export const AppPageScrollDownLoadMore = (e: any, loadMore: VoidFunction) => {
  const bottom =
    e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight * 1.01;
  if (bottom) {
    loadMore();
  }
};

export const CheckTokenExist = () => !!localStorage.getItem(constants.TOKEN);

export const SetToken = (value: UserResponse | AuthResponse) => {
  let newData;
  if (CheckTokenExist()) {
    // @ts-ignore
    const data = JSON.parse(
      // @ts-ignore
      atob(localStorage.getItem(constants.TOKEN))
    ) as AuthResponse;
    newData = { ...data, ...value };
  } else {
    newData = { ...value };
  }
  localStorage.setItem(constants.TOKEN, btoa(JSON.stringify(newData)));
};

export const GetToken = () => {
  if (CheckTokenExist()) {
    try {
      // @ts-ignore
      return JSON.parse(
        // @ts-ignore
        atob(localStorage.getItem(constants.TOKEN))
      ) as AuthResponse;
    } catch (e) {
      localStorage.removeItem(constants.TOKEN);
      window.location.replace("/login");
    }
  }
  return {};
};

export const GetUserToken = () => {
  if (CheckTokenExist()) {
    return (GetToken() as AuthResponse)!.access_token;
  }
  return "";
};

export const GetUserData = () => {
  if (CheckTokenExist()) {
    // @ts-ignore
    return (GetToken() as AuthResponse)!;
  }
  return null;
};

export const GetFullURL = (value: string) => {
  return (process.env.REACT_APP_API_ENDPOINT ?? "")
    .replace("/api/v4", "")
    .concat(value);
};

export const SearchLocal = (value: string, queries: Array<string> | string) => {
  let res = false;
  if (value.length < 1) {
    return true;
  } else if (Array.isArray(queries)) {
    if (queries.includes(value)) {
      res = true;
    } else {
      queries.forEach((e) => {
        if (e.includes(value)) {
          res = true;
          return;
        }
      });
    }
  } else {
    return queries.includes(value);
  }

  return res;
};

//Initialize GA
ReactGA.initialize("G-D2ZF7BEWY5");

export const SendAnalytics = () => {
  // @ts-ignore
  window.gtag("event", "page_view", {
    page_title: window.location.pathname,
    page_path: window.location.pathname + window.location.search,
    page_location: window.location.href,
  });

  return ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};

export enum AppState {
  IDLE,
  SUCCESS,
  ERROR,
  ERROR_404,
  LOADING,
  LOADED,
  ERROR_INPUT,
  LOADING_MORE,
  INFO,
  WARNING,
  DATA_REFRESH,
  PLAYING,
  PLAY,
  PAUSING,
  PAUSED,
  RETRY,
}

export enum ResourceTypes {
  IDLE,
  SUCCESS,
  AUDIO,
  VIDEO,
}

export const getContentProviderId = () =>
  process.env.REACT_APP_CONTENT_PROVIDER_ID;
export const getAppName = () => process.env.REACT_APP_APP_NAME ?? "eCampus";
export const getAppShortName = () =>
  process.env.REACT_APP_APP_SHORT_NAME ?? "eCampus";
export const getAppLogo = () => process.env.REACT_APP_APP_LOGO;
export const getAppFavicon = () => process.env.REACT_APP_APP_FAVICON;
export const getInstitutions = () =>
  process.env.REACT_APP_INSTITUTIONS
    ? [...process.env.REACT_APP_INSTITUTIONS.split(",")]
    : [];
export const ellipsisText = (text: string, value = 20) =>
  text.length > value ? text.slice(0, value).concat("..") : text;
export const getSignUpReferral = () => process.env.REACT_APP_SIGN_UP_REFERRAL;
export var getAuthImage = () => process.env.REACT_APP_AUTH_IMAGE;
export var getAuthSliderTexts = () => {
  let sliders: { title: string; desc: string }[] = [];
  // @ts-ignore
  const rawSliders = [...process.env.REACT_APP_AUTH_SLIDER_TEXT.split(",")];
  rawSliders.forEach((e) => {
    const slider = [...e.split("|")];
    if (slider.length === 1) {
      sliders.push({ title: "", desc: slider[0] });
    } else if (slider.length === 2) {
      sliders.push({ title: slider[0], desc: slider[1] });
    }
  });
  return sliders;
};

export enum LANGUAGES {
  EN,
  FR,
}

export const getLanguage = (key: string, lang: LANGUAGES) => {
  switch (lang) {
    case LANGUAGES.FR:
      return (fr as unknown as any)[key];
    default:
      return (en as unknown as any)[key];
  }
};

export var getLanguageByDefault = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { language } = useSelector((state: IRootState) => state.setting);
  switch (language) {
    case LANGUAGES.FR:
      return fr;
    default:
      return en;
  }
};

export const logger = (type: AppState, ...params: any) => {
  switch (type) {
    case AppState.ERROR:
      process.env.NODE_ENV !== "production" && console.error(...params);
      break;
    default:
      process.env.NODE_ENV !== "production" && console.log(...params);
  }
};

export const InfoLogger = (...params: any) => logger(AppState.INFO, params);
export const ErrorLogger = (...params: any) => logger(AppState.ERROR, params);
