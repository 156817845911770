import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
} from "react-bootstrap";
import Scrollbar from "smooth-scrollbar";
import {
  AppSidebarMenu,
  CheckContentProviderIdExist,
} from "../services/app.service";
import { IRootState } from "../redux/states";
import { useSelector } from "react-redux";
import SidebarProfile from "./SidebarProfile";
import { RouteEndpoints } from "../services/route.service";
import SignInModal from "./UnAuthorizedPage/SignInModal";

import HTMLReactParser from "html-react-parser";
interface props {
  eventKey: string;
  handleClick: KeyFunction;
  children: React.ReactNode;
}

interface KeyFunction {
  (key: any): void;
}

interface sideBarProps {
  triggerSidebar?: () => void;
}

function CustomToggle({ children, eventKey, handleClick }: props) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    handleClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={() => {
        // @ts-ignore
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const Sidebar = ({ triggerSidebar }: sideBarProps) => {
  useEffect(() => {
    // @ts-ignore
    Scrollbar.init(document.querySelector("#sidebar-scrollbar"));
  });

  const state = useSelector((state: IRootState) => state);
  const currentUser = state.setting.user!;
  let location = useLocation();
  const [show, setShow] = useState(false);
  const hideModal = () => setShow(false);

  return (
    <>
      <div className="iq-sidebar d-flex flex-column ">
        <div className="logo d-flex justify-content-between align-items-center">
          <svg
            width="100"
            height="40"
            viewBox="0 0 95 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.9546 14.8742C22.9546 16.0747 23.3529 17.0788 24.1496 17.8864C24.9572 18.694 26.0049 19.0977 27.2927 19.0977C28.264 19.0977 29.0825 18.8686 29.7482 18.4102C30.4139 17.9409 30.8668 17.4116 31.1069 16.8223L29.0934 16.2002C28.6896 16.986 28.0348 17.3461 27.129 17.2807C26.5178 17.2479 26.0376 17.0406 25.6884 16.6586C25.3501 16.2766 25.1809 15.8947 25.1809 15.5127L31.287 15.4472C31.3961 13.8211 31.0524 12.6097 30.2557 11.813C29.4699 11.0163 28.4713 10.618 27.2599 10.618C26.0704 10.618 25.0554 10.9836 24.2151 11.7148C23.3748 12.446 22.9546 13.4992 22.9546 14.8742ZM25.1809 13.892C25.2246 13.4555 25.421 13.0954 25.7703 12.8116C26.1304 12.5279 26.5178 12.3751 26.9325 12.3532C27.5328 12.3096 28.0293 12.4515 28.4222 12.7789C28.826 13.0954 29.0279 13.461 29.0279 13.8757L25.1809 13.892Z"
              fill="#1E1E1E"
            />
            <path
              d="M32.6104 13.1881C32.6104 11.442 33.2161 9.97957 34.4275 8.80092C35.6498 7.61135 37.074 7.01657 38.7001 7.01657C39.9333 7.01657 41.052 7.37126 42.056 8.08063C43.071 8.77909 43.8131 9.69036 44.2824 10.8144L42.1215 11.6002C41.7941 10.9018 41.3412 10.3288 40.7628 9.88135C40.1953 9.4339 39.5132 9.21017 38.7165 9.21017C37.6797 9.21017 36.7903 9.57577 36.0482 10.307C35.317 11.0273 34.9514 11.9876 34.9514 13.1881C34.9514 14.2467 35.3115 15.1362 36.0318 15.8565C36.7521 16.5658 37.647 16.9205 38.7165 16.9205C39.5568 16.9205 40.288 16.7077 40.9101 16.2821C41.5322 15.8565 41.9523 15.278 42.1706 14.5468L44.3315 15.3817C44.0041 16.4185 43.2947 17.3134 42.2033 18.0664C41.112 18.8195 39.9443 19.196 38.7001 19.196C37.0413 19.196 35.6116 18.623 34.4111 17.4771C33.2107 16.3203 32.6104 14.8906 32.6104 13.1881Z"
              fill="#1E1E1E"
            />
            <path
              d="M48.3771 19.196C47.5258 19.196 46.8546 19.005 46.3635 18.623C45.8724 18.241 45.5832 17.7117 45.4959 17.0351C45.4086 16.1839 45.6214 15.5345 46.1344 15.0871C46.6473 14.6287 47.3785 14.3395 48.328 14.2194C48.4371 14.2085 48.5953 14.1922 48.8027 14.1703C49.021 14.1376 49.1901 14.1103 49.3102 14.0885C49.4411 14.0667 49.583 14.0448 49.7358 14.023C49.8886 14.0012 50.0086 13.9739 50.0959 13.9411C50.1942 13.9084 50.2651 13.8757 50.3087 13.8429C50.3742 13.7884 50.4179 13.7011 50.4397 13.581C50.4724 13.461 50.4779 13.33 50.4561 13.1881C50.4342 13.0353 50.3469 12.888 50.1942 12.7461C50.0523 12.6043 49.8504 12.5006 49.5885 12.4351C49.0646 12.3041 48.6172 12.3478 48.2461 12.5661C47.875 12.7843 47.6404 13.1499 47.5422 13.6629L45.6269 13.2536C45.7797 12.3369 46.3526 11.6002 47.3457 11.0436C47.7823 10.7926 48.3007 10.6453 48.9009 10.6016C49.5121 10.5471 50.1068 10.6235 50.6853 10.8308C52.104 11.3656 52.8134 12.2823 52.8134 13.581L52.8625 18.9504H50.718L50.6525 18.0173C50.0959 18.8031 49.3374 19.196 48.3771 19.196ZM49.3102 17.4771C49.8122 17.3789 50.1832 17.1552 50.4233 16.8059C50.6634 16.4458 50.7507 16.0474 50.6853 15.6109C50.4015 15.6546 50.0741 15.6873 49.703 15.7091C49.3429 15.731 49.0537 15.7473 48.8354 15.7582C48.6281 15.7691 48.4207 15.8019 48.2134 15.8565C48.0169 15.9001 47.8696 15.982 47.7714 16.102C47.6841 16.2221 47.6404 16.3858 47.6404 16.5931C47.6404 16.9751 47.7932 17.2425 48.0988 17.3953C48.4153 17.548 48.8191 17.5753 49.3102 17.4771Z"
              fill="#1E1E1E"
            />
            <path
              d="M54.1762 10.8799H56.2716V12.0258C56.3589 11.8949 56.4789 11.753 56.6317 11.6002C56.7845 11.4365 57.0355 11.2455 57.3847 11.0273C57.7449 10.809 58.1105 10.678 58.4815 10.6344C59.2564 10.5362 59.8785 10.6453 60.3477 10.9618C60.8279 11.2674 61.1608 11.6439 61.3463 12.0913C61.5428 11.7312 61.8483 11.4201 62.2631 11.1582C62.6778 10.8854 63.1852 10.7108 63.7855 10.6344C64.353 10.5689 64.855 10.618 65.2915 10.7817C65.7281 10.9454 66.0664 11.1855 66.3065 11.502C66.5575 11.8185 66.743 12.1568 66.8631 12.5169C66.9831 12.8771 67.0431 13.2427 67.0431 13.6137V18.934H64.8332V14.2686C64.8332 13.1445 64.3857 12.5824 63.4908 12.5824C62.9779 12.5824 62.5577 12.7625 62.2303 13.1226C61.9138 13.4828 61.7556 14.0066 61.7556 14.6942V18.9504H59.5456V14.7433C59.5456 14.0121 59.4474 13.4719 59.2509 13.1226C59.0545 12.7734 58.6616 12.5988 58.0723 12.5988C56.9482 12.5988 56.3862 13.4228 56.3862 15.0707V18.9504H54.1762V10.8799Z"
              fill="#1E1E1E"
            />
            <path
              d="M68.3889 22.1099L68.3562 10.8308L70.4025 10.8472L70.4843 11.6984C71.0082 10.9454 71.8158 10.5689 72.9071 10.5689C73.3655 10.5689 73.8184 10.6726 74.2658 10.8799C74.7242 11.0873 75.1444 11.3819 75.5263 11.7639C75.9083 12.1459 76.203 12.6479 76.4103 13.27C76.6286 13.8811 76.7104 14.5523 76.6559 15.2835C76.6122 15.9056 76.4758 16.4622 76.2466 16.9533C76.0174 17.4444 75.7337 17.8372 75.3954 18.1319C75.0571 18.4266 74.7078 18.6558 74.3477 18.8195C73.9875 18.9832 73.611 19.0868 73.2181 19.1305C72.1377 19.2614 71.2373 18.9613 70.5171 18.2301L70.5334 22.0935L68.3889 22.1099ZM70.4843 14.8906C70.4843 15.5563 70.6589 16.1075 71.0082 16.544C71.3683 16.9696 71.8649 17.1879 72.4978 17.1988C73.0544 17.2097 73.5292 16.9915 73.9221 16.544C74.3259 16.0966 74.5277 15.54 74.5277 14.8742C74.5277 14.2413 74.3259 13.7011 73.9221 13.2536C73.5183 12.7952 73.0217 12.577 72.4324 12.5988C71.7339 12.6315 71.2319 12.8771 70.9263 13.3355C70.6317 13.7938 70.4843 14.3122 70.4843 14.8906Z"
              fill="#1E1E1E"
            />
            <path
              d="M83.4804 17.9518C83.3713 18.2356 83.0821 18.5139 82.6128 18.7867C82.1545 19.0486 81.5924 19.1796 80.9267 19.1796C80.4247 19.1796 79.9827 19.0868 79.6007 18.9013C79.2187 18.7158 78.9241 18.4921 78.7167 18.2301C78.5203 17.9682 78.362 17.6735 78.242 17.3461C78.1219 17.0078 78.0455 16.7295 78.0128 16.5113C77.991 16.2821 77.9801 16.0693 77.9801 15.8728V10.8472H80.2883V15.66C80.2883 16.0638 80.4083 16.4185 80.6484 16.7241C80.8994 17.0297 81.265 17.1824 81.7452 17.1824C82.2363 17.1824 82.6346 17.0406 82.9402 16.7568C83.2567 16.4622 83.415 16.1293 83.415 15.7582V10.8308H85.7068V18.9504H83.5623L83.4804 17.9518Z"
              fill="#1E1E1E"
            />
            <path
              d="M92.1527 13.27C92.0326 12.9644 91.8143 12.7352 91.4979 12.5824C91.1923 12.4296 90.9031 12.3532 90.6302 12.3532C90.3247 12.3532 90.0464 12.4133 89.7954 12.5333C89.5553 12.6534 89.4352 12.8116 89.4352 13.008C89.4352 13.2809 89.6426 13.4937 90.0573 13.6465C90.2101 13.7011 90.8158 13.8538 91.8744 14.1048C93.4132 14.4759 94.1826 15.3326 94.1826 16.675C94.1826 17.3079 93.8715 17.8918 93.2495 18.4266C92.6383 18.9504 91.7871 19.2123 90.6957 19.2123C89.7026 19.2123 88.9005 18.9886 88.2893 18.5412C87.6891 18.0828 87.2634 17.5426 87.0124 16.9205L88.9441 16.0856C89.0423 16.3585 89.2115 16.6095 89.4516 16.8387C89.7026 17.0678 89.9863 17.2152 90.3028 17.2807C90.663 17.3571 91.0286 17.3407 91.3996 17.2315C91.7816 17.1115 91.989 16.9096 92.0217 16.6259C92.0435 16.4076 91.9453 16.2221 91.727 16.0693C91.5088 15.9056 91.2632 15.791 90.9904 15.7255C90.7175 15.66 90.3901 15.5945 90.0082 15.5291C89.6262 15.4527 89.3643 15.3926 89.2224 15.349C88.6986 15.1853 88.2511 14.907 87.88 14.5141C87.509 14.1103 87.3235 13.6792 87.3235 13.2209C87.3235 12.577 87.5854 11.9822 88.1092 11.4365C88.644 10.8908 89.3588 10.5634 90.2537 10.4543C90.9522 10.367 91.6834 10.5089 92.4473 10.8799C93.2222 11.2401 93.746 11.7803 94.0189 12.5006L92.1527 13.27Z"
              fill="#1E1E1E"
            />
            <rect
              width="6.47079"
              height="6.47079"
              rx="0.980121"
              transform="matrix(0.733565 -0.679619 -0.679619 -0.733565 7.05566 28.2216)"
              fill="#EA983F"
            />
            <rect
              width="13.3897"
              height="7.76503"
              rx="0.980121"
              transform="matrix(0.987079 -0.160233 -0.160233 -0.987079 1.24426 19.8652)"
              fill="#DF6438"
            />
            <rect
              width="13.302"
              height="8.88115"
              rx="0.980121"
              transform="matrix(1 0 0 -1 3.59924 9.65929)"
              fill="#BB1C17"
            />
          </svg>

          <svg
            onClick={triggerSidebar}
            style={{ cursor: "pointer" }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-x-lg d-lg-none"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
        </div>
        <div id="sidebar-scrollbar">
          <nav className="iq-sidebar-menu d-flex flex-column gap-4">
            {!CheckContentProviderIdExist() && (
              <Link
                to={RouteEndpoints.POINTS}
                className="points-container d-flex flex-column justify-content-center align-items-center mx-auto"
              >
                <div className="points d-flex flex-column justify-content-center align-items-center">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="p-0 m-0"
                  >
                    <g clipPath="url(#clip0_847_826)">
                      <path
                        d="M8.59715 10.8153C11.3116 10.8153 13.512 8.62056 13.512 5.91318C13.512 3.2058 11.3116 1.01104 8.59715 1.01104C5.88273 1.01104 3.68225 3.2058 3.68225 5.91318C3.68225 8.62056 5.88273 10.8153 8.59715 10.8153Z"
                        stroke="#4CAF50"
                        strokeWidth="1.40061"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.93637 10.0387L5.08679 16.4185L8.59743 14.3175L12.1081 16.4185L11.2585 10.0317"
                        stroke="#4CAF50"
                        strokeWidth="1.40061"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_847_826">
                        <rect
                          width="16.8511"
                          height="16.8073"
                          fill="white"
                          transform="translate(0.171753 0.311218)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <b className="point p-0 m-0">{currentUser.points ?? 0}</b>
                  <small className="p-0 m-0">POINTS</small>
                </div>
              </Link>
            )}

            <Accordion as="ul" id="iq-sidebar-toggle" className="iq-menu">
              {AppSidebarMenu().map((item) => (
                <li
                  key={item.link}
                  className={`${
                    location.pathname === item.link ? "active" : ""
                  } `}
                >
                  <Link
                    to={
                      currentUser.ecampus_id
                        ? item.link
                        : RouteEndpoints.UNAUTH_HOME
                    }
                    onClick={() =>
                      currentUser.ecampus_id
                        ? window.scroll(0, 0)
                        : setShow(true)
                    }
                  >
                    {/*<i className={item.icon} />*/}
                    <div className={"p-1"}>{HTMLReactParser(item.svg)}</div>
                    <span className={"bold"}>{item.name}</span>
                  </Link>
                </li>
              ))}
            </Accordion>
          </nav>
        </div>
        {currentUser.ecampus_id && <SidebarProfile user={currentUser} />}
      </div>

      <SignInModal hideModal={hideModal} open={show} />
    </>
  );
};

export default Sidebar;
